@import 'src/constants/index.scss';

.hero {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 87vh;
  min-height: 600px;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: $bpLarge) {
    height: 64vh;
    min-height: 427px;
  }

  &.dark {
    color: $white;
  }

  &.light {
    color: $darkGrey;
  }

  .background-wrapper {
    div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      &.dark {
        display: none;

        @media screen and (max-width: $bpXXLarge) {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      &.light {
        display: none;

        @media screen and (max-width: $bpXXLarge) {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;

      @media screen and (max-width: $bpLarge) {
        position: absolute;
        height: 64vh;
        min-height: 427px;
        top: 0;
      }
    }
  }

  .content-wrapper {
    height: 64vh;
    display: flex;
  }

  .flex {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
    z-index: 2;

    @media screen and (max-width: 1150px) {
      display: none;
    }

    @media screen and (max-height: 690px) {
      display: none;
    }
  }

  .lit-effect {
    font-size: 20px;
    line-height: 30px;
    transform: rotate(-90deg);
    padding-bottom: 75px;
    margin-right: -68px;
    display: flex;
    align-items: flex-end;
    width: 77px;

    @media screen and (max-width: 1200px) {
      padding-bottom: 187px;
    }
  }

  ul {
    text-align: left;
    height: 404px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
  }

  li {
    font-family: $medium;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    width: 63px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    width: 63px;
  }

  .lit-icon {
    display: flex;

    svg {
      height: 56px;
      width: 56px;
    }

    &.dark {
      svg {
        fill: $white;
      }
      circle {
        stroke: $white;
      }
    }

    &.light {
      svg {
        fill: $darkGrey;
      }
      circle {
        stroke: $darkGrey;
      }
    }
  }

  .tick-icon {
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .type {
    font-family: $regular;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 2px;
    width: 85%;
  }

  .description-wrapper {
    max-width: 758px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    top: 18vh;
    right: 4.5%;
    z-index: 1;

    @media screen and (max-width: $bpLarge) {
      top: 102%;
      left: 20px;
      max-width: 100%;
    }

    span {
      display: inline-block;
    }

    .cut {
      background: $salmonGrad;
      border-radius: 4px;
      color: $white;
      font-family: $medium;
      font-size: 20px;
      line-height: 42px;
      text-align: center;
      height: 42px;
      width: auto;
      padding: 0 30px;

      @media screen and (max-width: $bpLarge) {
        position: absolute;
        top: -30px;
        right: 0;
      }
    }
  }
}

.variants {
  font-size: 30px;
  line-height: 30px;
  text-align: right;
  margin: 20px 0 24px 0;

  @media screen and (max-width: $bpLarge) {
    display: none;
  }
}
