@import 'src/constants/index.scss';

.promotional-module {
  height: 600px;
  max-width: 1215px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: 600px) {
    height: 80vh;
  }
  .slick-list {
    width: 100%;
  }
  .background-slide {
  }
  > div {
    margin-top: 0;
  }
}

.back-to-video {
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: underline;
  cursor: pointer;
  z-index: 100;
  color: white;
  font-size: 0.8em;
}

.navigations {
  width: 600px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  right: 40px;
  color: white;
  z-index: 100;
  > button {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 720px) {
    margin-top: -280px;
    width: 90%;
    position: relative;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    text-align: center;
    /* right: 40px; */
    color: white;
    z-index: 100;
    text-align: center;
    z-index: 20;
    /* bottom: 20px; */
    margin-left: auto;
    margin-right: auto;
    top: auto;
    transform: none;
    transform: translateY(-100%);
    margin-top: -40px !important;
    right: auto;
  }
}

.next-button {
  width: 45px;
}

.prev-button {
  width: 45px;
}

.video-overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 110;
  position: absolute;
  opacity: 1;
  transition: opacity 0.5s;
  video {
    height: 100%;
    width: 100%;
    opacity: 0.8;
    object-fit: cover;
  }
  .details {
    position: absolute;
    z-index: 20;
    color: white;
    bottom: 0;
    padding: 80px;
    padding-bottom: 70px;
    max-width: 720px;
    p {
      margin-top: 10px;
      margin-bottom: 30px;
    }
    span {
      display: block;
      width: min-content;
    }
    @media screen and (max-width: $bpSmall) {
      text-align: center;
      padding: 20px;
      padding-bottom: 40px;
      span {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.finish-name {
  padding-right: 20px;
}

.invisible {
  opacity: 0;
}
.hidden {
  display: none;
}

.colour-btn {
  margin-left: auto;
  margin-right: auto;
}

.nav-holder {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  > div {
    margin-top: 0;
    width: calc(100% - 100px);
  }
}

.gradient-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgb(0, 0, 0);
  background: -webkit-radial-gradient(
    at 86% 59%,
    rgba(0, 0, 0, 0.4766281512605042) 34%,
    rgba(0, 0, 0, 0) 72%,
    rgba(0, 0, 0, 0) 100%
  );
  background: radial-gradient(
    at 86% 59%,
    rgba(0, 0, 0, 0.4766281513) 34%,
    rgba(0, 0, 0, 0) 72%,
    rgba(0, 0, 0, 0) 100%
  );
}

.promotional-module-closed-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 75px;
  /* margin-bottom: 75px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotional-module-closed {
  /* max-width: 1100px; */
  width: 100%;
  display: flex;
  justify-content: center;
  background-position: center center;
  background-size: cover;
}

.pm-closed-overlay {
  width: 100%;
  height: 100%;
  background: rgba(241, 138, 119, 1);
  /* background: linear-gradient(
    270deg,
    rgba(179, 145, 84, 1) 47%,
    rgba(40, 44, 49, 0) 100%
  ); */

  /* padding-left: 30px; */
  display: flex;
  justify-content: center;
  color: white;
}

.pm-textbox {
  h3 {
    padding-bottom: 10px;
    display: none;
  }
  p {
    padding-bottom: 3px;
    display: none;
  }
  button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #e0dacb, #c8a76d);
  padding: 2rem;
  border-radius: 8px;
  overflow: hidden;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.textContainer {
  flex: 1;
  padding: 1rem 2rem;
  color: #fff;
  text-align: left;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #fff;
}

.description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.button {
  background-color: #2f2f4f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: #1f1f3f;
  }
}

@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    text-align: center;
  }

  .textContainer {
    padding: 1rem;
  }

  .button {
    width: 100%;
  }
}
