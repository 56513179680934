@import 'src/constants/index.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bpLarge) {
    flex-direction: row;
  }
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  img,
  video {
    width: 100%;
  }
  video {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: 100%;
    object-fit: cover;
  }

  .bg-color {
    background: $salmonGrad;
    height: 13px;
    width: 100%;
  }
}

.text-content {
  padding: 50px 41px 30px 33px;

  @media screen and (min-width: $bpLarge) {
    margin: 0 auto;
    padding: 50px 20px 0 0;
  }

  span {
    display: inline-block;
  }

  p {
    margin-top: 20px;
  }

  h2 {
    margin-bottom: 12px;
    text-transform: lowercase;
  }
}

/* styling per section
*/
.clarity {
  background: $white;
  padding: 70px 0;

  @media screen and (min-width: $bpSmall) {
    padding: 92px 0;
  }

  .wrapper {
    @media screen and (min-width: $bpLarge) {
      flex-direction: row-reverse;
    }
    &.picfirst {
      @media screen and (min-width: $bpLarge) {
        flex-direction: row;
      }
    }
  }

  .text-content {
    padding: 50px 41px 30px 33px;

    @media screen and (min-width: $bpLarge) {
      width: 30%;
      min-width: 372px;
      padding-right: 45px;
    }
  }

  .image-wrapper {
    margin: 0;
    width: 100%;

    @media screen and (min-width: $bpLarge) {
      max-width: 965px;
      margin-left: 45px;
    }
  }
}

.circular-reverse {
  background: $white;
  padding: 70px 0;

  @media screen and (min-width: $bpSmall) {
    padding: 92px 0;
  }

  .wrapper {
    @media screen and (min-width: $bpLarge) {
      flex-direction: row;
    }
    &.picfirst {
      @media screen and (min-width: $bpLarge) {
        flex-direction: row;
      }
    }
  }

  .text-content {
    padding: 50px 41px 30px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $bpLarge) {
      width: 30%;
      min-width: 535px;
      padding-right: 45px;
      padding-left: 119px;
    }
  }

  .image-wrapper {
    margin: 0;
    width: 100%;

    @media screen and (min-width: $bpLarge) {
      max-width: 965px;
      margin-left: 45px;
    }
  }
}

.circular {
  background: $white;

  @media screen and (min-width: $bpSmall) {
  }

  .wrapper {
    @media screen and (min-width: $bpLarge) {
      flex-direction: row-reverse;
    }
  }

  .text-content {
    padding: 50px 41px 30px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      width: 100%;
    }

    @media screen and (min-width: $bpLarge) {
      width: 30%;
      min-width: 484px;
      padding-right: 45px;
    }
  }

  .image-wrapper {
    margin: 0;
    width: 100%;

    @media screen and (min-width: $bpLarge) {
      max-width: 965px;
      margin-left: 45px;
    }
  }

  p {
    margin-bottom: 35px;
  }
}

.midi-reverse {
  background: $white;
  padding: 70px 0;

  @media screen and (min-width: $bpSmall) {
    padding: 92px 0;
  }

  > div {
    flex-direction: column-reverse;
    @media screen and (min-width: $bpLarge) {
      flex-direction: row-reverse;
    }
    &.picfirst {
      @media screen and (min-width: $bpLarge) {
        flex-direction: row-reverse;
      }
    }
  }

  .text-content {
    padding: 50px 41px 30px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    position: relative;

    @media screen and (min-width: $bpLarge) {
      width: 30%;
      min-width: 535px;
      padding-right: 45px;
      padding-left: 45px;
    }
    h2,
    span {
      text-align: center;
      @media screen and (max-width: $bpMedium) {
        text-align: left;
      }
    }
    button {
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $bpMedium) {
        margin-left: 0;
      }
    }
  }

  .image-wrapper {
    margin: 0;

    @media screen and (min-width: $bpLarge) {
      max-width: 965px;
      width: 50%;
    }
  }
}

.midi {
  background: $white;
  padding: 70px 0;

  @media screen and (min-width: $bpSmall) {
    padding: 92px 0;
  }

  > div {
    flex-direction: column-reverse;
    @media screen and (min-width: $bpLarge) {
      flex-direction: row;
    }
    &.picfirst {
      @media screen and (min-width: $bpLarge) {
        flex-direction: row;
      }
    }
  }

  .text-content {
    padding: 50px 41px 30px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $bpLarge) {
      width: 30%;
      min-width: 535px;
      padding-right: 45px;
      padding-left: auto;
    }
  }

  .image-wrapper {
    margin: 0;
    margin-right: 50px;

    @media screen and (min-width: $bpLarge) {
      max-width: 965px;
      width: 50%;
    }
  }
}

.caresses {
  background: $rose;
  margin-bottom: 68px;

  .text-content {
    padding-top: 47px;
    padding-bottom: 50px;

    @media screen and (min-width: $bpLarge) {
      max-width: 52%;
      padding-left: 32px;
      padding-bottom: 0;
    }

    @media screen and (min-width: $bpXXLarge) {
      max-width: 388px;
      padding-top: 92px;
      padding-left: 0;
    }

    h2 {
      max-width: 500px;
    }
  }

  .image-wrapper {
    @media screen and (min-width: $bpLarge) {
      max-width: 836px;
    }

    @media screen and (min-width: $bpXXLarge) {
      margin-right: 75px;
    }
  }
}
