@import 'src/constants/index.scss';

.contact {
  margin-top: 14px;

  .wrapper {
    padding-left: 127px;
    position: relative;

    @media screen and (max-width: 1350px) {
      padding-left: 50px;
    }

    @media screen and (max-width: $bpXXLarge) {
      padding-left: 30px;
    }

    .title {
      margin-bottom: 68px;

      @media screen and (max-width: $bpSmall) {
        margin-bottom: 0;
        width: 271px;
      }
    }
  }

  .side-image {
    position: absolute;
    top: -229px;
    right: 0;

    @media screen and (max-width: 1350px) {
      top: -149px;
    }

    @media screen and (max-width: $bpXXLarge) {
      display: none;
    }

    img {
      width: 100%;

      @media screen and (max-width: 1350px) {
        height: 573px;
        width: auto;
      }

      @media screen and (max-width: $bpXXLarge) {
        height: 473px;
      }
    }
  }

  .confirmation {
    color: $darkBlue;
    margin-top: 43px;
    animation: slide-up 0.3s ease-in forwards;
  }

  .error {
    color: $salmon;
    margin-top: 43px;
    animation: slide-up 0.3s ease-in forwards;
  }
}
