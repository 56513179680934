@import 'src/constants/index.scss';

.page-statement {
  background: none;
  padding: 25px 0;

  h2 {
    text-transform: lowercase;
  }

  .content {
    &.small {
      font-size: 35px;
      line-height: 45px;

      @media screen and (max-width: $bpSmall) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    &.big {
      line-height: 70px;
      margin-left: 200px;
    }
  }

  &.project-info {
    @media screen and (max-width: $bpSmall) {
      padding: 0;
    }

    .content {
      margin-top: 50px;
      max-width: 870px;

      @media screen and (max-width: $bpXLarge) {
        margin-right: 20px;
        margin-left: 50px;
      }

      @media screen and (max-width: $bpSmall) {
        line-height: 40px;
        margin-left: 28px;
      }

      @media screen and (max-width: 500px) {
        max-width: 318px;
      }
    }
  }

  &.our-story {
    margin-top: 40px;

    @media screen and (max-width: 840px) {
      margin-top: 65px;
      padding-top: 0;
    }

    .content {
      max-width: 904px;
      margin-left: 230px;
      margin-bottom: 25px;

      @media screen and (max-width: 1200px) {
        margin-left: 130px;
      }

      @media screen and (max-width: $bpLarge) {
        margin-left: 70px;
        padding-right: 20px;
      }

      @media screen and (max-width: $bpSmall) {
        padding-left: 33px;
        margin-bottom: 30px;
        margin-left: 0;
      }
    }
  }

  &.why-orluna {
    padding: 50px 0;

    .content {
      max-width: 904px;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-left: 125px;

      @media screen and (max-width: $bpMedium) {
        padding-right: 20px;
      }

      @media screen and (max-width: 840px) {
        padding-left: 33px;
      }
    }
  }
}
