@import 'src/constants/index.scss';

.range-button {
  composes: filter-toggle from '../search/style.module.scss';

  @media screen and (min-width: $bpSmall) {
    background-color: $offWhite;
    padding: 18px;
    margin: 0;
    text-align: right;
  }

  @media screen and (min-width: $bpLarge) {
    display: none;
  }
}

.display-none {
  display: none;
}

.display-inline {
  display: inline-block;
}

.is-product-page {
  background-color: $offWhite;
  margin: 84px 0 0;
  padding: 18px;
  text-align: right;
  width: 100%;
  opacity: 0;
  transform: translateY(-100%);
  will-change: transform, opacity;
  // when on the top of the page it needs to disappear faster
  transition: transform 0.01s, opacity 0.01s;

  &:hover {
    opacity: 1;
  }
}

.is-visible {
  transition: transform 0.5s, opacity 0.5s;
  transform: translateY(0);
  opacity: 1;
}

.is-open {
  svg {
    transform: rotate(-180deg);
  }
}
