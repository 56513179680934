@import 'src/constants/index.scss';

.slider {
  color: $darkGrey;
  margin-top: 150px;
  padding-top: 50px;
  position: relative;

  @media screen and (max-width: $bpMedium) {
    margin-top: 50px;
  }

  .intro {
    display: inline-block;
    position: absolute;
    left: 113px;
    top: 17px;

    @media screen and (max-width: $bpXLarge) {
      left: 75px;
    }

    @media screen and (max-width: $bpSmall) {
      left: 40px;
    }

    &.related-title {
      display: block;
      position: relative;
      left: unset;
      top: unset;
      width: 100%;

      h3 {
        text-align: center;
        margin-bottom: -20px;
      }
    }

    h3 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 55px;
    }
  }

  .slide {
    position: relative;

    &.dark {
      color: $white;
    }

    &.light {
      color: $darkGrey;
    }

    &:focus {
      outline: none;
    }

    a {
      &:focus {
        outline: none;
      }
    }

    .caption {
      position: absolute;
      left: 15px;
      bottom: 15px;
      margin: 0;
      z-index: 5;

      @media screen and (max-width: $bpMedium) {
        bottom: 15px;
      }

      .slide-title {
        font-size: 24px;
        font-family: $medium;
        line-height: 42px;
        text-transform: lowercase;

        @media screen and (max-width: $bpXLarge) {
          line-height: 28px;
        }
      }

      .description {
        max-width: 202px;
      }
    }
  }

  .view-all {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;

    @media screen and (max-width: $bpXLarge) {
      margin-top: 30px;
    }
  }

  .bottom-overlay {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(107, 114, 148, 0) 0%,
      rgba(57, 62, 91, 0.2) 98%
    );
    z-index: 2;
  }

  /* Related products
  */
  &.square-small,
  &.featured {
    margin-top: 70px;
    padding: 0;

    @media screen and (max-width: 980px) {
      margin-top: 0;
    }

    a {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &.featured {
    .related-title {
      justify-content: center;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      h3 {
        text-align: center;
        margin-bottom: 15px;
        padding-bottom: 20px;
      }
    }
  }

  /* News carousel 
  */
  &.landscape-small {
    margin-top: 0;

    img {
      @media screen and (min-width: $bpSmall) {
        max-width: 380px;
      }
    }

    .caption {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .slide-title {
        font-size: 20px;
        line-height: 22px;

        @media screen and (min-width: $bpSmall) {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}
