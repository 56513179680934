@import 'src/constants/index.scss';

.range-grid-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 68px;
  }
}

.is-product-listing {
  margin-top: 147px;

  @media screen and (min-width: $bpLarge) {
    margin-top: 207px;
  }

  @media screen and (min-width: $bpXLarge) {
    margin-top: 196px;
  }

  .intro {
    display: none;
  }
}

.range-nav-hidden {
  margin-top: 144px;

  @media screen and (min-width: $bpXLarge) {
    margin-top: 136px;
  }
}

.intro {
  margin: 62px auto;
  max-width: 1195px;
  padding: 0 27px 0 32px;

  @media screen and (min-width: $bpMedium) {
    padding: 0 20px;
  }

  p,
  h2 {
    text-align: left;

    @media screen and (min-width: $bpMedium) {
      text-align: center;
    }
  }

  h2 {
    margin-bottom: 37px;
  }
}

.range-grid {
  display: grid;
  grid-template-columns: 1fr;
  // Note: Safari does not like 1fr for rows
  grid-auto-rows: 100px;
  grid-gap: 10px;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;

  @media screen and (min-width: $bpSmall) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 158px;
  }

  @media screen and (min-width: $bpXLarge) {
    grid-auto-rows: 316px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  div {
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;

      &:hover {
        cursor: pointer;

        span {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    img,
    video {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    video {
      display: none;

      @media screen and (min-width: $bpSmall) {
        display: block;
        z-index: 1;
      }
    }

    p {
      text-align: center;
      pointer-events: none;
      z-index: 1;
    }

    span {
      color: white;
      font-size: 28px;
      font-weight: bold;
      line-height: 30px;
      position: relative;

      @media screen and (min-width: $bpXLarge) {
        font-size: 35px;
        line-height: 32px;

        &::after {
          content: '';
          background: white;
          position: absolute;
          bottom: -3px;
          right: 50%;
          transform: translateX(50%);
          height: 3px;
          width: 100%;
          opacity: 0;
          transition: opacity 1s;
        }
      }
    }
  }
}

@media screen and (min-width: $bpXLarge) {
  .range-six,
  .range-nine {
    div {
      &:nth-of-type(6) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
      }
    }
  }

  .range-six,
  .range-seven,
  .range-nine {
    div {
      &:first-of-type {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
      }
    }
  }

  .range-nine {
    div {
      &:nth-of-type(7) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }
    }
  }
}
