@import 'src/constants/index.scss';

.bg-image,
.people,
.wider,
.get-in-touch,
.get-in-touch-home,
.get-in-touch-sustainability,
.need-repairs,
.vertical-copy,
.orluna-so-far {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  h1,
  h2,
  h3 {
    color: $white;
    margin-bottom: 35px;
    text-transform: lowercase;

    &.light {
      color: $darkGrey;
    }
    &.dark {
      color: $white;
    }
  }

  p {
    &.light {
      color: $darkGrey;
    }
    &.dark {
      color: $white;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.bg-image,
.people,
.wider,
.vertical-copy {
  button {
    display: none;
  }
}

.people,
.get-in-touch,
.get-in-touch-home,
.get-in-touch-sustainability,
.need-repairs,
.vertical-copy,
.orluna-so-far {
  height: 78vh;
  max-height: 632px;
  min-height: 632px;

  @media screen and (max-width: $bpSmall) {
    height: 632px;
  }

  @media screen and (max-width: $bpXSmall) {
    height: 565px;
  }
}

/* styling per section
*/
.bg-image {
  height: 78vh;
  max-height: 632px;
  z-index: 2;
}

.people {
  @media screen and (max-width: $bpSmall) {
    display: none;
  }
}

.wider {
  height: 42vh;
  max-height: 412px;

  @media screen and (max-width: $bpXXLarge) {
    background-size: contain;
  }

  @media screen and (max-width: 820px) {
    background-position: right;
    background-size: cover;
  }
}

// homepage
.vertical-copy {
  position: relative;
  margin-bottom: 190px;

  &.extra-margin-bottom {
    margin-bottom: 290px;

    @media screen and (max-width: $bpSmall) {
      margin-bottom: 390px;
    }
  }

  &.has-bg-color {
    margin-bottom: 43px;

    @media screen and (max-width: 1300px) {
      margin-bottom: -35px;
    }

    @media screen and (max-width: 650px) {
      margin-bottom: -200px;
    }
  }

  @media screen and (max-width: 650px) {
    padding-top: 42px;
  }

  .bg-color {
    height: 133px;
    width: 1032px;
    opacity: 0.7;
    background: $darkBlueGrad;
    position: absolute;
    bottom: -133px;
    left: 297px;

    @media screen and (max-width: 1350px) {
      left: 180px;
    }

    @media screen and (max-width: $bpXXLarge) {
      left: 5vw;
      width: 85%;
    }

    @media screen and (max-width: 640px) {
      left: 50%;
      height: 133px;
      width: 327px;
      transform: translateX(-50%);
    }
  }

  .content {
    h2 {
      position: absolute;
      left: -118px;
      top: 294px;
      width: 495px;
      text-shadow: 0 7px 29px rgba(162, 162, 162, 0.5);
      transform: rotate(-90deg);

      @media screen and (max-width: $bpXXLarge) {
        left: -198px;
      }

      @media screen and (max-width: $bpSmall) {
        width: 300px;
        left: -115px;
        top: 225px;
      }

      &.light {
        color: $darkGrey;
      }
      &.dark {
        color: $white;
      }
    }
  }
}

// Why Orluna page, our-story cta
.orluna-so-far {
  margin-top: 50px;

  @media screen and (max-width: $bpSmall) {
    margin-top: 20px;
    background-position: top;
  }

  .content {
    position: absolute;
    right: 105px;
    top: 164px;
    max-width: 690px;

    @media screen and (max-width: $bpXLarge) {
      right: 15px;
      top: 80px;
      max-width: 490px;
    }

    @media screen and (max-width: $bpSmall) {
      right: initial;
      top: 209px;
      left: 43px;
      max-width: 285px;
    }

    @media screen and (max-width: $bpXSmall) {
      left: 15px;
    }

    h3 {
      margin-bottom: 50px;
      max-width: 405px;

      @media screen and (max-width: $bpSmall) {
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 35px;
      }
    }

    h1 {
      margin-bottom: 17px;

      @media screen and (max-width: $bpSmall) {
        max-width: 251px;
      }
    }
  }
}

.get-in-touch,
.get-in-touch-home,
.get-in-touch-sustainability,
.need-repairs {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $bpSmall) {
    margin-top: 28px;
  }

  .content {
    margin-right: 95px;
  }
}

.get-in-touch-home {
  @media screen and (max-width: $bpSmall) {
    margin-top: 0;
  }
}

.get-in-touch-sustainability {
  justify-content: flex-start;
  .content {
    margin-left: 100px;
  }
}

// contact-page and faqs
.get-in-touch,
.get-in-touch-sustainability,
.need-repairs {
  .content {
    position: relative;
    max-width: 600px;

    @media screen and (max-width: $bpSmall) {
      left: 28px;
      max-width: 250px;
    }
  }
}

// homepage
.get-in-touch-home {
  .content {
    max-width: 415px;
    margin-right: 179px;

    @media screen and (max-width: $bpXLarge) {
      margin-right: 49px;
    }

    @media screen and (max-width: $bpSmall) {
      margin-right: 33px;
      max-width: 240px;
      position: absolute;
      top: 39px;
      left: 30px;
    }

    h2 {
      max-width: 400px;

      @media screen and (max-width: $bpSmall) {
        max-width: 240px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 30px;

      @media screen and (max-width: $bpSmall) {
        font-size: 16px;
        line-height: 22px;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.extra-margin-bottom {
  margin-bottom: 50px;
}
