.showcase {
  max-height: calc(100vh - 150px);
  /* width: 100%; */
  aspect-ratio: 16 / 9;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  @media screen and (max-width: 711px) {
    max-height: none;
    aspect-ratio: 9 / 16;
  }
  video {
    object-fit: cover;
    object-position: center center;
  }
}

.showcase-fullscreen {
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 75px);
}

.message-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  > div {
    max-width: 1200px;
    display: flex;
    color: white;
    align-items: left top;
    padding: 50px;
    padding-top: 50px;
    p {
    }
  }
}
