@import 'src/constants/index.scss';

.realise-and-enhance {
  color: $darkGrey;

  @media screen and (max-width: $bpMedium) {
    font-size: 16px;
    line-height: 22px;
    padding-top: 0;
  }
}

.flex {
  display: flex;
  justify-content: center;

  &.wrapper {
    flex-direction: column;
  }
}

.realise {
  flex-direction: column-reverse;

  @media screen and (min-width: $bpSmallPlus) {
    flex-direction: row;
  }
}
.realise--reverse {
  flex-direction: column;

  @media screen and (min-width: $bpSmallPlus) {
    flex-direction: row;
  }
}

.enhance {
  position: relative;
  flex-direction: column;

  @media screen and (min-width: $bpSmallPlus) {
    flex-direction: row;
  }
}

.negative-margin {
  margin-top: 0;

  @media screen and (min-width: $bpSmallPlus) {
    margin-top: -83px;
  }
}

.realise-left-side,
.realise-right-side {
  width: 100%;

  @media screen and (min-width: $bpSmallPlus) {
    height: auto;
    width: 50%;
  }

  @media screen and (min-width: 1460px) {
    height: 939px;
    width: 720px;
  }
}

.realise-left-side {
  position: relative;

  @media screen and (min-width: $bpSmallPlus) {
    height: initial;
  }
  .realise-content {
    @media screen and (max-width: 779px) {
      min-height: 400px;
      img:nth-of-type(1) {
        display: none !important;
      }
      img:nth-of-type(2) {
        display: block !important;
      }
    }
    @media screen and (min-width: 779px) {
      img:nth-of-type(1) {
        display: block !important;
      }
      img:nth-of-type(2) {
        display: none !important;
      }
    }
  }

  // callout component
  div {
    height: 100%;

    margin: 0 auto;

    video {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;

      @media screen and (min-width: $bpSmallPlus) {
        height: 95%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }

    img {
      display: block;
      object-fit: contain;
      width: 138%;
      object-position: 0 150px;
      height: 138%;
      margin-left: -345px;
      @media screen and (max-width: 779px) {
        display: block;
        object-fit: contain;
        width: 100%;
        object-position: 0 0;
        height: 100%;
        margin-left: 0;
      }
    }
  }

  span {
    font-size: 35px;
    line-height: 45px;
    position: absolute;
    left: 124px;
    top: 50px;
    max-width: 362px;
    z-index: 2;

    @media screen and (max-width: $bpXXLarge) {
      left: 56px;
    }

    @media screen and (max-width: $bpMedium) {
      left: 28px;
      max-width: 320px;
    }

    @media screen and (max-width: $bpSmall) {
      font-size: 25px;
      line-height: 30px;
    }

    @media screen and (max-width: $bpXSmall) {
      font-size: 22px;
      line-height: 27px;
      max-width: 251px;
    }
  }

  h3 {
    padding-left: 124px;
    padding-top: 45px;

    @media screen and (max-width: $bpXLarge) {
      padding-left: 56px;
      width: 406px;
    }

    @media screen and (max-width: $bpMedium) {
      font-size: 25px;
      line-height: 30px;
      width: 280px;
    }

    @media screen and (max-width: 420px) {
      padding-left: 31px;
    }
  }
}

.realise-right-side {
  position: relative;

  @media screen and (max-width: $bpSmallPlus) {
    height: 414px;
    width: 100%;
    &.no-min-height {
      height: auto;
    }
  }

  .realise-content {
    display: flex;
    flex-direction: column;
    margin-left: 91px;
    margin-top: 236px;
    max-width: 567px;

    @media screen and (max-width: 1460px) {
      margin-bottom: 100px;
      margin-top: 100px;
    }

    @media screen and (max-width: $bpXLarge) {
      margin-left: 51px;
    }

    @media screen and (max-width: $bpLarge) {
      margin-top: 120px;
      margin-bottom: 120px;
    }

    @media screen and (max-width: $bpMedium) {
      max-width: 320px;
    }

    @media screen and (max-width: $bpSmallPlus) {
      margin: 65px auto;
      max-width: 313px;
    }

    @media screen and (max-width: 420px) {
      margin-left: 35px;
    }

    h3 {
      color: $salmon;
      line-height: 70px;
      margin-top: 170px;
      margin-bottom: 80px;
      max-width: 100%;
      opacity: 0.2;

      @media screen and (max-width: $bpXLarge) {
        margin-top: 100px;
      }

      @media screen and (max-width: $bpLarge) {
        font-size: 28px;
        line-height: 30px;
        margin-top: 90px;
        margin-bottom: 40px;
      }

      @media screen and (max-width: $bpMedium) {
        font-size: 25px;
      }

      @media screen and (max-width: $bpSmallPlus) {
        display: none;
      }
    }
  }
}

.enhance-left-side {
  height: 878px;
  width: 720px;

  @media screen and (max-width: $bpXLarge) {
    height: auto;
    width: 50%;
  }

  @media screen and (max-width: $bpSmallPlus) {
    height: 393px;
    width: 100%;
  }

  .enhance-content {
    max-width: 509px;
    margin-top: 229px;
    padding-left: 136px;

    @media screen and (max-width: 1200px) {
      margin-top: 220px;
      padding-left: 56px;
    }

    @media screen and (max-width: $bpXLarge) {
      margin-top: 120px;
      margin-bottom: 170px;
    }

    @media screen and (max-width: $bpLarge) {
      max-width: 409px;
    }

    @media screen and (max-width: $bpMedium) {
      max-width: 320px;
    }

    @media screen and (max-width: $bpSmallPlus) {
      max-width: 292px;
      margin: 65px auto;
      padding-left: 0;
    }

    @media screen and (max-width: 420px) {
      margin-left: 30px;
    }
  }
}

.enhance-content,
.realise-content {
  position: relative;
  z-index: 2;

  h2 {
    @media screen and (max-width: $bpLarge) {
      font-size: 45px;
      line-height: 55px;
    }

    @media screen and (max-width: $bpMedium) {
      font-size: 35px;
      line-height: 40px;
    }
  }

  p {
    margin-top: 35px;
    margin-bottom: 56px;

    @media screen and (max-width: $bpLarge) {
      line-height: 22px;
      margin-top: 35px;
      margin-bottom: 46px;
      max-width: 395px;
    }

    @media screen and (max-width: $bpSmallPlus) {
      margin-bottom: 36px;
    }
  }

  button {
    width: 276px;
    height: 50px;

    @media screen and (max-width: $bpXSmall) {
      width: 226px;
    }
  }
}

.enhance-right-side {
  height: 795px;
  width: 720px;

  @media screen and (max-width: $bpXLarge) {
    height: auto;
    width: 50%;
  }

  @media screen and (max-width: $bpSmallPlus) {
    height: 578px;
    width: 100%;
  }
}

.right-side-content {
  height: 100%;
  &--hidesecond {
    height: 100%;
    @media screen and (max-width: 779px) {
      img {
        display: none;
      }
    }
  }
}

.right-side-content-middle {
  height: 100%;
  display: flex;
  align-items: center;
}
