@import 'src/constants/index.scss';

.circular-interactive {
  background-color: #f3ded6;
  padding-top: 40px;
  padding-bottom: 40px;
  > div {
    display: flex;
    align-items: center;
    > div {
      width: 100%;
      &:nth-child(1) {
        width: 60%;
        position: relative;
        .video-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          -moz-box-shadow: inset 0 0 100px 100px #f3ded6;
          -webkit-box-shadow: inset 0 0 100px 100px #f3ded6;
          box-shadow: inset 0 0 100px 100px #f3ded6;
        }
      }
      &:nth-child(2) {
        width: 40%;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      > div {
        width: 100%;
        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          width: 100%;
          min-height: 250px;
        }
      }
    }
  }
}
video {
  width: 100%;
}
.final-side-note {
  padding: 30px;
  display: none;
  > div {
    display: block;
    h2 {
      opacity: 0;
      margin-top: 0;
      padding-top: 0;
      color: $salmon;
      font-size: 6em;
    }
    img {
      width: 100%;
    }
    p {
      margin-top: 20px;
      padding-top: 0;
      opacity: 0;
      color: white;
      span {
        color: $salmon;
        font-weight: bold;
      }
    }
    .play-again {
      color: white;
      cursor: pointer;
    }
  }
}
.side-note {
  padding: 30px;
  display: none;
  > div {
    display: flex;
    @media screen and (max-width: 768px) {
      margin: 20px 1%;
    }
    h2 {
      opacity: 0;
      margin-top: 0;
      padding-top: 0;
      color: $salmon;
      font-size: 6em;
      @media screen and (max-width: 768px) {
        line-height: 60px;
      }
    }
    > div {
      padding-left: 15px;
      p {
        padding-top: 0;
        opacity: 0;
        color: white;
      }
    }
  }
  p.below {
    margin-top: 15px;
    opacity: 0;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      height: 1em;
      path {
        fill: white;
      }
    }
    span {
      opacity: 0;
      padding-right: 20px;
    }
  }
}
