@import 'src/constants/index.scss';

.sign-up {
  padding: 0;

  .wrapper {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 370px;
    margin-bottom: 50px;

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
      flex-wrap: wrap;
      padding: 50px 0;
    }

    .content {
      padding-top: 63px;
      padding-left: 20px;
      max-width: 50%;
      flex: 1;

      @media screen and (max-width: $bpSmall) {
        padding-top: 0;
        padding-left: 5%;
        margin-top: 40px;
        width: 100%;
        max-width: 100%;
      }

      p {
        line-height: 22px;
        width: 363px;
        padding-bottom: 26px;
        padding-right: 15px;

        @media screen and (max-width: $bpSmall) {
          margin: 0 auto;
        }

        @media screen and (max-width: 375px) {
          margin: 0;
          width: auto;
        }
      }

      .form-wrapper {
        position: relative;
        width: auto;
        max-width: 363px;

        @media screen and (max-width: $bpSmall) {
          margin: 0 auto;
        }
      }
    }

    .confirmation {
      max-width: 400px;
      width: 100%;
      padding-left: 30px;
      margin-right: 20px;
      animation: slide-up 0.3s ease-in forwards;

      @media screen and (max-width: $bpSmall) {
        margin: 0 auto;
      }
    }

    .error {
      @extend .confirmation;
      color: $salmon;
    }
  }
}
