@import 'src/constants/index.scss';

.downloads {
  @media screen and (max-width: $bpLarge) {
    background: rgba($offWhite, 0.4);
    //margin-bottom: 30px;
    padding: 20px 0 35px 0;
  }

  .inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 0 10px;

    @media screen and (min-width: 540px) {
      padding: 0 30px;
    }

    @media screen and (min-width: $bpLarge) {
      display: grid;
    }

    @media screen and (min-width: 1300px) {
      padding: 0 120px;
    }

    &:first-of-type {
      @media screen and (min-width: $bpLarge) {
        margin-bottom: 54px;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  button,
  .title {
    cursor: default;
    pointer-events: none;
  }
}

.heading {
  padding-bottom: 30px;

  @media screen and (max-width: 540px) {
    padding-left: 12px;
    padding-bottom: 0;
  }

  span {
    color: $midGrey;
    display: none;
    margin-top: 20px;

    @media screen and (min-width: $bpLarge) {
      display: inline-block;
    }
  }
}

.download-btn-container {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;

  button {
    border: none;
    color: $midGrey;
    font-size: 20px;
    line-height: 32px;
    text-align: right;
    text-decoration: underline;
    display: flex;
    align-items: center;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }

    &:first-of-type {
      margin-right: 33px;
    }
  }
}

.title,
.info {
  color: $darkBlue;
  font-family: $medium;
  font-size: 18px;
  height: 50px;
  line-height: 23px;
}

.filter-header {
  color: $darkGrey;
  font-size: 18px;
  height: 50px;
  margin-top: 20px;

  span {
    cursor: pointer;
  }
}

.filter-options {
  text-align: center;

  @media screen and (max-width: 1023px) {
    margin: 0;
    max-width: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 103px;
    padding-right: 85px;

    @media screen and (max-width: 1420px) {
      padding-left: 8.5vw;
      padding-right: 0;
    }

    @media screen and (max-width: 1200px) {
      padding-left: 9.5vw;
    }

    @media screen and (max-width: 1023px) {
      padding-bottom: 50px;
      padding-left: 4vw;
    }

    @media screen and (max-width: 780px) {
      padding-left: 2vw;
    }

    @media screen and (max-width: 540px) {
      padding-left: 3.5vw;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: 1023px) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      position: relative;
    }
  }

  &.hide {
    display: none;
  }

  label {
    font-size: 13px;
    line-height: 16px;
    display: flex;

    @media screen and (max-width: 1023px) {
      padding-left: 33px;
    }

    input {
      height: 23px;
    }

    span {
      text-align: left;
    }
  }
}

.download-zip {
  height: 40px;
  width: 292px;
  border-radius: 20px;
  border: 0;
  background-color: $darkBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  margin: 20px auto;

  @media screen and (max-width: $bpXXLarge) {
    width: 235px;
  }

  &.disabled {
    pointer-events: none;
    background-color: $grey;
  }
}

.select,
.preview {
  color: rgba($darkBlue, 0.5);
  font-size: 12px;
  line-height: 26px;
  text-align: right;

  @media screen and (max-width: $bpLarge) {
    color: $darkBlue;
  }
}

.left-column {
  @media screen and (max-width: 1023px) {
    display: none;
  }

  .filter-options {
    ul {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
    }

    label {
      align-items: center;
    }
  }

  .preview {
    padding-right: 15px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .filename {
    color: $midGrey;
    font-family: $regular;
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
  }

  .header {
    font-size: 16px;
    line-height: 20px;
    padding-right: 5px;
    margin-left: 5px;

    @media screen and (min-width: $bpXLarge) {
      font-size: 18px;
      line-height: 30px;
      margin-left: 20px;
      padding-right: 10px;
    }
  }
}

.right-column {
  .filter-header {
    height: initial;
    flex-direction: column;

    .filter-options {
      display: none;
    }

    &.is-open {
      .title {
        border-bottom-color: white;

        @media screen and (max-width: $bpLarge) {
          border-bottom-color: rgba($offWhite, 0);
        }
      }

      .arrow {
        transform: rotate(-180deg);
      }

      .filter-options {
        display: block;
      }
    }
  }

  .title {
    border-bottom: 2px solid $darkBlue;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 10fr;

    @media screen and (min-width: $bpLarge) {
      grid-template-columns: 1fr 4fr;
    }

    @media screen and (min-width: 1420px) {
      grid-template-columns: 1fr 5fr;
    }
  }

  .header {
    margin-left: 0;
    padding-left: 7px;

    @media screen and (max-width: 780px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .icon {
    padding-left: 39px;

    @media screen and (max-width: $bpLarge) {
      padding-left: 12px;
    }
  }

  .filter-options {
    border-bottom: 2px solid $darkBlue;
  }

  .subtext-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 103px;
    padding-right: 100px;

    @media screen and (max-width: 1420px) {
      padding-left: 8.5vw;
      padding-right: 17px;
    }

    @media screen and (max-width: 1200px) {
      padding-left: 9.5vw;
    }

    @media screen and (max-width: 1023px) {
      padding-left: 5vw;
    }

    @media screen and (max-width: 780px) {
      padding-left: 3.5vw;
    }

    @media screen and (max-width: 540px) {
      padding-left: 6vw;
    }
  }

  .select-preview {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .subtext {
    color: $darkBlue;
    font-size: 12px;
    line-height: 26px;
    margin-top: -14px;
    text-align: left;
  }
}

.desktop {
  display: none;

  @media screen and (min-width: $bpLarge) {
    display: block;
    padding-bottom: 0;
  }
}

.mobile {
  @media screen and (min-width: $bpLarge) {
    display: none;
  }
}

/* 
  * icons styling 
  */
.icon {
  @media screen and (max-width: 780px) {
    padding-left: 12px;
    margin-right: 16px;
  }

  @media screen and (max-width: $bpXSmall) {
    margin-right: 8px;
  }

  .double-icon {
    margin-left: -22px;
  }
}

.pdf {
  width: 24px;
  height: 34px;
  margin: 0 5px;
}

.image-big {
  fill: $darkBlue;
  height: 25px;
  width: 33px;
}

.download-icon {
  width: 27px;
  height: 27px;
  margin-left: 10px;
}

.preview-icon {
  margin-left: 10px;
  display: flex;

  @media screen and (max-width: 1023px) {
    display: flex;
    position: absolute;
    padding: 5px 0;
    width: 100%;
    z-index: 5;
  }

  svg {
    fill: $darkBlue;
    margin-right: 30px;
    height: 19px;
    width: 15px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.arrow {
  height: 7px;
  width: 10px;
  fill: $darkBlue;
  margin-left: 12px;
  margin-bottom: 2px;
  transition: transform 0.3s ease-in;

  @media screen and (max-width: $bpSmall) {
    position: absolute;
    right: 20px;
  }

  circle {
    fill: none;
  }
} /* end icon styling*/
