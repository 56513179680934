@import 'src/constants/index.scss';

.notification {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  margin: 0 auto 16px auto;
  max-width: 93%;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: $bpSmall) {
    position: relative;
    margin: 0;
    max-width: 100%;
    max-height: 60px;
    z-index: 201; // higher than the header
  }

  div {
    padding: 13px 5px 13px 35px;
    border-radius: 4px;

    @media screen and (min-width: $bpSmall) {
      border-radius: 0;
      text-align: center;
      padding: 16px;
    }
  }

  a {
    font-family: $medium;
    text-decoration: underline;
  }

  p {
    display: inline-block;
    margin-right: 3px;
  }

  p,
  a {
    font-size: 16px;
    line-height: 22px;

    @media screen and (min-width: $bpSmall) {
      color: $white;
    }
  }
}

.pink {
  background: $salmonGrad;

  @media screen and (min-width: $bpSmall) {
    background: $black;
  }
}

.blue {
  background: $darkBlueGrad;
  color: $white;

  @media screen and (min-width: $bpSmall) {
    background: $black;
  }
}

.hide {
  display: none;
}
