@import 'src/constants/index.scss';

.design-and-beauty {
  color: $darkGrey;
  padding-bottom: 49px;
  margin-top: 112px;

  @media screen and (max-width: $bpLarge) {
    margin-top: 60px;
  }

  @media screen and (max-width: $bpMedium) {
    padding-bottom: 0;
  }

  @media screen and (max-width: $bpSmall) {
    margin-top: 46px;
  }

  .flex {
    display: flex;
    justify-content: center;

    &.wrapper {
      flex-direction: column;
    }

    @media screen and (max-width: 780px) {
      &.design {
        flex-direction: column;
      }

      &.beauty {
        flex-direction: column-reverse;
      }
    }
  }

  h3 {
    font-size: 25px;
    line-height: 30px;

    @media screen and (min-width: $bpMedium) {
      font-size: 35px;
      line-height: 40px;
    }
  }

  button {
    margin-top: 43px;
    width: 276px;

    @media screen and (max-width: $bpXSmall) {
      width: 226px;
    }
  }

  .design-left-side {
    height: 581px;
    width: 720px;

    @media screen and (max-width: $bpXLarge) {
      height: auto;
    }

    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }

  .design-left-side {
    @media screen and (max-width: 780px) {
      height: 583px;
    }
  }

  .design-right-side {
    position: relative;
    height: 630px;
    width: 720px;

    @media screen and (max-width: 780px) {
      height: 283px;
      width: 100%;
    }

    .design-right-side-content {
      display: flex;
      flex-direction: column;
      margin-left: 115px;
      margin-top: 180px;
      max-width: 567px;

      @media screen and (max-width: $bpXLarge) {
        max-width: 395px;
        margin-left: 51px;
      }

      @media screen and (max-width: $bpLarge) {
        margin-top: 120px;
      }

      @media screen and (max-width: $bpMedium) {
        max-width: 320px;
      }

      @media screen and (max-width: 780px) {
        margin: 65px auto;
        max-width: 313px;
      }

      @media screen and (max-width: 420px) {
        margin-left: 35px;
      }
    }
  }

  .beauty-left-side {
    background: #f2d9cf;
    height: 548px;
    width: 720px;
    margin-top: -49px;

    @media screen and (max-width: 780px) {
      margin-top: 0;
      height: 283px;
      width: 100%;
    }

    .beauty-left-side-content {
      margin-top: 219px;
      padding-left: 125px;

      @media screen and (max-width: 1200px) {
        margin-top: 220px;
        padding-left: 56px;
      }

      @media screen and (max-width: $bpXLarge) {
        margin-top: 120px;
        margin-bottom: 170px;
      }

      @media screen and (max-width: $bpLarge) {
        max-width: 409px;
      }

      @media screen and (max-width: $bpMedium) {
        max-width: 320px;
      }

      @media screen and (max-width: 780px) {
        max-width: 300px;
        margin: 65px auto;
        padding-left: 0;
      }

      @media screen and (max-width: 420px) {
        margin-left: 30px;
      }
    }
  }

  .beauty-right-side {
    height: 560px;
    width: 720px;

    @media screen and (max-width: 780px) {
      height: 578px;
      width: 100%;
    }
  }
}

.floating-written {
  opacity: 0.06;
  text-align: right;
  transform: rotate(-90deg) translateX(50%);
  transform-origin: 100% 100%;
  position: absolute;
  right: 0;
  bottom: 454px;
  overflow: hidden;

  @media screen and (max-width: 1146px) {
    display: none;
  }

  h2 {
    font-size: 220px;
    line-height: 1em;
    white-space: nowrap;
  }
}
