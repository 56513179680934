@import 'src/constants/index.scss';

.line-drawings {
  padding: 0 13px 102px 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: $bpSmall) {
    padding-top: 102px;
  }

  @media screen and (min-width: $bpLarge) {
    flex-direction: row;
  }

  &::before {
    content: '';
    background: $rose;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  h2 {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    margin: 42px 0 40px 0;

    @media screen and (min-width: $bpSmall) {
      display: none;
    }
  }

  span {
    div {
      left: -106px;
      bottom: -550%;

      @media screen and (max-width: $bpXLarge) {
        bottom: -50px;
        background-size: 380px;
        max-width: 100%;
      }
    }
  }
}

.section-wrapper {
  display: grid;

  @media screen and (min-width: $bpSmall) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: $bpLarge) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: $bpLarge) {
    width: initial;
  }

  a {
    display: none;

    @media screen and (min-width: $bpSmall) {
      color: $midGrey;
      font-size: 18px;
      display: block;
      line-height: 30px;
      margin-top: 22px;
      text-decoration: underline;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.option {
  background: $white;
  width: 100%;
  max-width: 470px;
  display: flex;
  padding: 31px 12px 38px 21px;
  position: relative;

  @media screen and (min-width: $bpSmall) {
    padding: 26px 5px 28px 20px;
  }

  @media screen and (min-width: $bpLarge) {
    padding-left: 40px;
    margin: 0 30px;
  }

  &:last-of-type {
    margin-top: 30px;

    @media screen and (min-width: $bpLarge) {
      margin-top: 0;
    }
  }

  &::before {
    content: '';
    background: linear-gradient(225deg, #ffffff 0%, #f3cbbe 100%);
    height: 104px;
    width: 100%;
    position: absolute;
    z-index: 0;
    left: -10px;
    bottom: -10px;
    z-index: -1;

    @media screen and (min-width: $bpLarge) {
      width: 412px;
    }
  }

  a {
    display: block;
    text-decoration: none;
    margin-top: 0;
  }

  img {
    height: 80px;
    width: 64px;

    @media screen and (min-width: $bpSmall) {
      height: 145px;
      width: 115px;
    }
  }

  div {
    margin-left: 25px;

    @media screen and (min-width: $bpLarge) {
      margin-left: 51px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 30px;
  }

  p {
    opacity: 0.5;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 0;
    max-width: 235px;

    @media screen and (min-width: $bpSmall) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }

  button {
    height: 40px;
    width: 200px;
    display: none;

    @media screen and (min-width: $bpSmall) {
      display: block;
    }
  }
}

.disabled {
  .option {
    a,
    div,
    h3 {
      opacity: 0.7;
    }
  }
  pointer-events: none;
}
