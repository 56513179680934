@import 'src/constants/index.scss';

.work-with-us {
  margin-top: 150px;

  @media screen and (max-width: $bpXLarge) {
    margin-top: 100px;
  }

  .wrapper {
    padding-left: 127px;
    position: relative;

    @media screen and (max-width: 1350px) {
      padding-left: 50px;
    }

    @media screen and (max-width: $bpXXLarge) {
      padding-left: 30px;
    }

    .floating-image {
      position: absolute;
      top: -229px;
      right: 0;

      @media screen and (max-width: 1350px) {
        top: -149px;
      }

      @media screen and (max-width: 1200px) {
        display: none;
      }

      img {
        width: 100%;

        @media screen and (max-width: 1350px) {
          height: 573px;
          width: auto;
        }

        @media screen and (max-width: $bpXXLarge) {
          height: 473px;
        }
      }
    }
  }

  .confirmation {
    color: $darkBlue;
    margin-top: 43px;
    animation: slide-up 0.3s ease-in forwards;
  }

  .error {
    color: $salmon;
    margin-top: 43px;
    animation: slide-up 0.3s ease-in forwards;
  }
}

.title {
  padding-right: 29px;

  h1 {
    max-width: 210px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: $bpSmall) {
      max-width: 100%;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px;

    @media screen and (min-width: $bpSmall) {
      font-size: 18px;
      line-height: 30px;
    }

    &:first-of-type {
      margin-top: 30px;

      @media screen and (min-width: $bpSmall) {
        margin-top: 50px;
      }
    }
  }
}
