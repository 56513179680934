@import 'src/constants/index.scss';

.page-title {
  margin-bottom: -84px;
  margin-top: 150px;
  padding-left: 4%;
  z-index: 5;

  @media screen and (max-width: 1280px) {
    padding-left: 11%;
  }

  @media screen and (min-width: 940px) and (max-width: 1020px) {
    margin-bottom: -98px;
  }

  @media screen and (max-width: $bpSmall) {
    padding-left: 0;
    margin-top: 110px;
    line-height: 65px;
    width: 345px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: -40px;
  }
}
