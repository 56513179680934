@import 'src/constants/index.scss';

.people-hero {
  margin-top: 134px;
  padding-left: 17px;
  overflow: hidden;

  @media screen and (max-width: $bpXLarge) {
    padding-left: 17px;
  }

  p {
    font-size: 35px;
    font-family: $medium;
    line-height: 45px;
    margin-top: 78px;
    margin-right: 9px;
    max-width: 350px;
    text-transform: lowercase;

    @media screen and (max-width: 1170px) {
      padding-right: 5px;
      margin-top: 44px;
    }

    @media screen and (max-width: $bpXLarge) {
      max-width: 50%;
      padding-right: 5px;
      order: 2;
    }

    @media screen and (max-width: $bpSmall) {
      font-size: 25px;
      line-height: 30px;
      margin-top: 13px;
      padding-right: 0;
      max-width: 240px;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.content {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $bpXLarge) {
    margin-top: 37px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: $bpSmall) {
    justify-content: flex-end;
  }

  @media screen and (max-width: 640px) {
    justify-content: flex-start;
  }

  div {
    height: 406px;

    @media screen and (max-width: $bpSmall) {
      height: 231px;
    }

    &:first-of-type {
      margin-right: 13px;
      max-width: 625px;

      @media screen and (max-width: $bpXLarge) {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100%;
        order: 1;
      }
    }

    &:last-of-type {
      max-width: 319px;

      @media screen and (max-width: $bpXLarge) {
        max-width: 50%;
        order: 3;
      }

      @media screen and (max-width: 640px) {
        margin-right: -25%;
      }
    }
  }
}
