@import 'src/constants/index.scss';

.led-components-section {
  h3 {
    margin-bottom: 16px;
  }
  > p {
    margin-bottom: 21px;
    max-width: 480px;
  }
  width: 100%;
  max-width: 1214px;
  margin: 0 auto;
  padding: 70px 20px 0 20px;

  @media screen and (min-width: $bpLarge) {
    display: block;
  }

  @media screen and (min-width: 1230px) {
    padding: 100px 0 0;
  }
  .led-table {
    width: 100%;
    th {
      background-color: #f3ded6;
      &:first-child {
        background-color: white;
      }
    }
    tr {
      background-color: rgb(244, 244, 244);
      min-height: 1em;
    }
    td {
      text-align: center;
      padding: 0px 10px;
      font-size: 0.8em;
    }
  }

  .component-name {
    max-width: 1.5em;
    p {
      transform: rotate(-90deg);
      text-wrap: nowrap;
    }
    vertical-align: middle;
    text-align: center;
    background-color: white;
  }

  .spacer-row {
    height: 30px;
  }
  .first-td {
    background-color: white;
  }
}
