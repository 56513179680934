@import 'src/constants/index.scss';

.callout {
  display: flex;
  width: 100%;
  max-width: 1192px;
  margin: 58px auto;
  position: relative;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $bpXLarge) {
    margin: 113px auto;
  }

  img,
  video {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}

.smaller {
  width: 85%;
  max-width: 733px;
}
