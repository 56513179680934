@import 'src/constants/index.scss';

.contact-details {
  max-width: 342px;
  min-width: 244px;
  margin-right: 110px;

  @media screen and (max-width: $bpXLarge) {
    margin-right: 45px;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }

  .logo-icon {
    fill: $darkBlue;
    height: 42px;
    width: 42px;
    margin-bottom: 9px;
    opacity: 0.8;

    :global(#prefix__radial-gradient-icon) {
      stop {
        stop-color: $darkBlue;
      }
    }
  }

  address {
    color: $darkBlue;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 32px;

    a {
      display: inline-block;
      padding-top: 31px;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .phone-desktop {
    @media screen and (max-width: 541px) {
      display: none;
    }
  }

  .phone-mobile {
    display: none;

    @media screen and (max-width: 541px) {
      display: block;
      padding-top: 0;
    }
  }

  .sales-link {
    color: $blue;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }

    svg {
      fill: $blue;
      margin-left: 10px;
      height: 16px;
      width: 46px;
    }
  }
}
