@import 'src/constants/index.scss';

.map {
  height: 93vh;

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.mobile-map {
  height: 361px;

  @media screen and (min-width: 540px) {
    display: none;
  }
}

.custom-legend {
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 110px;
  width: 243px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  position: relative;
  bottom: 109px;
  left: 7px;

  @media screen and (max-width: 540px) {
    display: none;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 23px;
      margin-right: 20px;
    }

    &:nth-child(2) {
      margin-top: 8px;

      img {
        margin-left: 5px;
        height: 10px;
        margin-right: 25px;
      }
    }
  }
}

.info-container {
  padding-left: 10px;

  p,
  a {
    color: $darkBlue;
    font-size: 14px;
    line-height: 18px;
  }

  .phone,
  .general-email,
  .directions {
    margin-top: 24px;
  }

  .directions {
    display: inline-block;

    &:after {
      content: url('../../assets/images/arrow.svg');
      position: absolute;
      opacity: 0.8;
      margin-left: 10px;
      margin-top: 2px;
      height: 14px;
      width: 33px;
    }

    svg {
      height: 14px;
      width: 33px;
    }
  }
}
