@import 'src/constants/index.scss';

.filters {
  display: flex;
  flex-wrap: wrap;
}

.clear {
  align-self: flex-end;
  text-align: center;
  margin: 5px 0;
  width: 100%;
  background: transparent;
  border: none;
  color: #555762;
  font-family: 'LL Circular Medium Sub';
  font-size: 18px;
  line-height: 32px;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (min-width: $bpMedium) {
    width: auto;
    margin: 0;
  }
}
