@import 'src/constants/index.scss';

.tech-spec {
  display: none;

  @media screen and (min-width: $bpLarge) {
    display: block;
    padding-top: 0;
  }

  .inner {
    @media screen and (min-width: $bpLarge) {
      display: flex;
      justify-content: center;
    }
  }

  button {
    border: none;
    background: transparent;
    border-radius: 31px;
    position: relative;
    font-family: $regular;
    color: $midGrey;
    will-change: color;
    transition: color 0.3s;
    width: 100%;
    z-index: 2;

    &::after {
      content: '';
      background-color: $grey;
      border-radius: 31px;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      transition: background-color 0.3s;
      z-index: -1;
    }

    &:focus {
      outline: none;
    }

    &.active {
      background-color: $darkBlue;
      color: $white;
      z-index: 3;

      &::after {
        background-color: $darkBlue;
      }

      &:hover {
        &::after {
          background-color: $darkBlue;
        }
      }
    }

    &:hover {
      color: $white;
      &::after {
        background-color: $blue;
      }
    }
  }

  .left-column {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
    padding-right: 50px;
  }

  .right-column {
    width: 60%;
  }

  .tech-spec-title {
    color: $darkGrey;
    font-family: $medium;
    font-size: 29px;
    line-height: 45px;
    margin-bottom: 16px;

    @media screen and (min-width: $bpXXLarge) {
      font-size: 35px;
    }
  }

  .tech-spec-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: right;
    width: 315px;

    @media screen and (min-width: $bpXXLarge) {
      font-size: 18px;
      line-height: 30px;
      width: 409px;
    }
  }

  .colour-temperature-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    .colour-temperature-container {
      flex: 3;
      justify-content: space-around;
      display: flex;
      max-width: 541px;
      background: $grey;
      border-radius: 31px;

      button {
        font-size: 25px;
        line-height: 55px;
        text-transform: uppercase;
        max-width: 270px;

        @media screen and (min-width: $bpXXLarge) {
          font-size: 35px;
        }
      }
    }
  }

  .lit-effect-toggle {
    display: flex;
    align-items: center;

    .lit-effect-container {
      background-color: $grey;
      border-radius: 31px;
      display: flex;
      flex: 3;
      justify-content: space-around;
      margin: 24px auto 0 auto;
      max-width: 542px;

      button {
        text-transform: lowercase;
        font-size: 20px;
        line-height: 43px;
        max-width: 180px;
        width: 100%;
      }
    }
  }

  .table {
    width: 100%;
  }

  .table-left {
    .table-row-left {
      height: 33px;

      &:first-of-type {
        text-decoration: underline;
      }
    }
  }

  .table-right {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 51px;
    padding-left: 30px;

    @media screen and (min-width: $bpXXLarge) {
      padding-left: 116px;
    }

    .table-row-right {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      height: 35px;

      &:first-of-type {
        text-decoration: underline;

        .values {
          font-size: 15px;
        }
      }
    }
  }

  .headers,
  .values {
    font-size: 16px;
    line-height: 29px;
    color: $midGrey;
  }

  .headers {
    width: 100%;
  }

  .values {
    width: 29%;
  }
}
