@import 'src/constants/index.scss';

.headquarters {
  margin-top: -45px;
  margin-bottom: 100px;

  @media screen and (max-width: 540px) {
    margin-top: -18px;
    margin-bottom: 55px;
  }

  .title {
    padding-left: 110px;
    margin-bottom: 30px;

    @media screen and (max-width: $bpMedium) {
      padding-left: 50px;
    }

    @media screen and (max-width: $bpSmall) {
      padding-left: 28px;
    }
  }

  .content-wrapper {
    display: flex;
    max-width: 1085px;
    padding-left: 110px;

    @media screen and (max-width: $bpMedium) {
      padding-left: 50px;
    }

    @media screen and (max-width: $bpSmall) {
      padding-left: 28px;
    }

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
    }

    a {
      position: relative;

      &:after {
        content: '';
        color: inherit;
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 0;
        border-style: solid;
        border-width: 1px;
        transition: opacity 0.5s;
        opacity: 0;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    .left-column {
      margin-right: 205px;

      @media screen and (max-width: $bpXLarge) {
        margin-right: 105px;
      }

      @media screen and (max-width: $bpMedium) {
        margin-right: 75px;
      }

      @media screen and (max-width: $bpSmall) {
        margin-right: 45px;
      }

      .phone-desktop {
        @media screen and (max-width: 541px) {
          display: none;
        }
      }

      .phone-mobile {
        display: none;

        @media screen and (max-width: 541px) {
          display: block;
        }
      }
    }

    .right-column {
      @media screen and (max-width: $bpSmall) {
        margin-top: 32px;
      }

      address {
        font-style: normal;
        max-width: 307px;
      }

      span {
        display: flex;
        align-items: center;
        margin-top: 29px;

        a {
          color: $midGrey;
          font-family: $medium;
          font-size: 20px;
          line-height: 32px;
        }

        .arrow {
          margin-left: 34px;
          height: 20px;
          width: 58px;
        }
      }
    }
  }
}
