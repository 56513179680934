@import 'src/constants/index.scss';

.tooltip {
  background-color: $offWhite;
  border-radius: 12px;
  color: $blue;
  display: flex;
  align-items: center;
  margin-top: 5px;
  //position: absolute;
  //top: calc(100% - 25px);
  //right: 20px;
  overflow: hidden;
  span {
    img {
      border: none !important;
    }
  }

  @media screen and (min-width: $bpMedium) {
    right: 12px;
    top: 128px;
  }

  @media screen and (min-width: $bpXLarge) {
    background-color: rgba($offWhite, 0);
    transition: background-color 0.3s;
    will-change: background-color;

    &:hover {
      background-color: rgba($offWhite, 1);
      span {
        &:first-of-type {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  span {
    &:first-of-type {
      background-color: $offWhite;
      border-radius: 12px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      color: $blue;
      font-size: 13px;
      line-height: 16px;
      padding: 3px 8px 3px 17px;
      padding: 0 30px 0 10px;

      @media screen and (min-width: $bpXLarge) {
        opacity: 0;
        transform: translateX(110%);
        transition: transform 0.3s, opacity 0.3s;
        will-change: transform, opacity;
      }
    }

    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $offWhite;
      border-radius: 100%;
      height: 24px;
      width: 24px;
      z-index: 2;
    }

    svg {
      fill: $darkGrey;
      position: relative;
      right: unset;
      top: unset;
      left: unset;
      height: 14px;
      width: 13px;

      path {
        fill: $blue;
      }
    }
  }
}
