.circular-product-strip {
  width: 100%;
  background-image: url('../../assets/images/circularbg.png');
  background-size: cover;
  background-position: center center;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 100px 0px;
  min-height: 415px;
  justify-content: center;
  h2 {
    margin: 50px 0 0;
    margin-top: 0;
    img {
      height: 40px;
    }
  }
  > div {
    @media screen and (max-width: 1024px) {
      margin: 50px 15px;
      h2 {
        img {
          max-width: 400px;
          width: 100%;
          height: auto;
        }
      }
    }
    > div {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
      > div {
        display: flex;
        justify-content: center;
        p {
          margin-top: 50px;
          border-radius: 44px;
          background-color: rgba(255, 255, 255, 0.5);
          height: 88px;
          width: 334px;
          font-size: 44px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'LL Circular Medium Sub';
          span {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
