@import 'src/constants/index.scss';

.gallery {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1190px;
  margin: 0 auto;
  padding-bottom: 50px;

  @media screen and (min-width: $bpMedium) {
    flex-direction: row;
    padding: 0 23px;
  }

  @media screen and (min-width: 1215px) {
    padding: 0;
  }
}

.video {
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-area: 1 / 1 / 2 / 2;

  video {
    object-fit: contain;
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  &:after {
    content: url('../../assets/images/play_icon.png');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);

    @media screen and (min-width: $bpSmall) {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  &.play,
  &.hide-icon {
    &:after {
      display: none;
    }
  }

  &.hide-icon {
    height: initial;

    video {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.left-side {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.5s;
  will-change: transform;

  @media screen and (min-width: $bpMedium) {
    max-width: 590px;
    width: 50%;
  }

  // colourful bar
  div.gradient {
    height: 15px;
    width: 100%;
  }

  img,
  video {
    width: 100%;
    height: 539px;
    object-fit: cover;
    display: block;
  }
}

.pink {
  background: $salmonGrad;
}

.blue {
  background: $darkBlueGrad;
}

.right-side {
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: $bpMedium) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 139px;
    margin-left: 5px;
    width: 45%;
    max-width: initial;
  }

  button {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 45px;
    transition: opacity 0.3s;
    margin: 42px auto 55px auto;

    @media screen and (min-width: $bpMedium) {
      margin: 0;
      padding-right: 0;
    }

    &:hover {
      opacity: 0.5;

      svg {
        transform: translateX(8px);
      }
    }
  }

  svg {
    height: 20px;
    width: 58px;
    margin-left: 8px;
    transition: transform 0.3s;
  }
}

.grid {
  padding: 0 33px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  grid-gap: 0.5rem;
  width: 100%;

  @media screen and (min-width: $bpMedium) {
    padding: 0;
  }

  > div {
    cursor: pointer;
    position: relative;

    // for aspect ratio
    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &::after {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(107, 114, 148, 0.72) 0%,
        rgba(57, 62, 91, 0.72) 100%
      );
      opacity: 0;
      transition: opacity 0.5s;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }

    &:hover {
      &::after {
        opacity: 0.8;
      }
    }
  }

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    display: block;
  }

  p {
    color: $white;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    pointer-events: none;
    position: absolute;
    bottom: 12px;
    left: 54%;
    margin: 0;
    text-shadow: 1px 2px 3px rgba(101, 101, 101, 0.6);

    @media screen and (min-width: $bpMedium) {
      bottom: 22px;
    }
  }

  svg {
    fill: $salmon;
    height: 9px;
    width: 26px;
  }

  &.thumbnails {
    background: $white;
    justify-content: flex-start;
    max-height: 100%;
    max-width: 120px;
    overflow-y: scroll;
    width: 100%;
    transition: transform 0.3s, width 0.5s;
    will-change: transform, width;

    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    padding: 5px;
    grid-gap: 0.3rem;

    @media screen and (min-width: $bpMedium) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      max-width: 419px;
    }

    &.hide {
      transform: translateX(100%);
      width: 0;
      padding: 0;

      // Safari
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: inherit;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: inherit;

      @media screen and (min-width: $bpMedium) {
        width: 10px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: $midGrey;
    }

    .selected {
      img,
      video {
        border: 3px solid $salmon;
      }
    }

    p {
      display: none;
    }
  }
}
