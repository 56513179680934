@import 'src/constants/index';

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html,
body {
  width: 100%;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: $darkGrey;
  font-family: $regular, sans-serif;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  min-height: 100vh;

  @media screen and (max-width: $bpSmall) {
    font-size: 16px;
    line-height: 22px;
  }
}

button {
  font-family: $regular, sans-serif;
  border: 0;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  font-family: $medium;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: $h1;
  line-height: 120px;

  @media screen and (max-width: $bpSmall) {
    font-size: $h1Mobile;
    line-height: 65px;
  }
}

h2 {
  font-size: $h2;
  line-height: 70px;

  @media screen and (max-width: $bpSmall) {
    font-size: $h2Mobile;
    line-height: 40px;
  }
}

h3 {
  font-size: $h3;
  line-height: 45px;

  @media screen and (max-width: $bpSmall) {
    font-size: $h3Mobile;
    line-height: 30px;
  }
}

h4 {
  font-size: $h4;
  line-height: 32px;

  @media screen and (max-width: $bpSmall) {
    font-size: $h4Mobile;
    line-height: 22px;
  }
}

p,
figure {
  margin: 0;
}

small {
  font-size: 11px;
  line-height: 13px;
  color: rgba($midGrey, 0.5);
}

/*
  global classes
*/
.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.section {
  width: 100%;
  padding: 50px 0;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
}

.nav-link-active {
  &:after {
    content: '';
    color: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-style: solid;
    border-width: 1px;
  }
}

.flex-between,
.flex-center {
  display: flex;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

@media screen and (max-width: $bpSmall) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (min-width: $bpSmall) {
  .hide-on-desktop {
    display: none;
  }
}

.slick-list {
  width: 100%;
}
.slick-slider,
.slick-list,
.slick-track {
  height: 100%;
  width: 100%;
}

.slick-slide {
  > div {
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      @media screen and (max-width: 720px) {
        object-position: 25% center;
      }
    }
  }
}
.background-slide.slick-slider {
  .slick-slide {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    position: absolute !important;
    left: 0 !important;
  }

  .slick-active {
    opacity: 1;
    transition: opacity 0.6s ease-in;
  }

  .slick-cloned {
    opacity: 0.6;
  }
  .slick-track {
    left: 0;
  }
}
