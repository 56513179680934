@import 'src/constants/index.scss';

.range-navigation-mobile {
  composes: filter-options from '../filters/main-filters/style.module.scss';
  background-color: $white;
  padding: 0;

  ul {
    width: 100%;
  }

  @media screen and (min-width: $bpLarge) {
    display: none;
  }
}

.links {
  composes: filter-option from '../filters/main-filters/main-filter-options/style.module.scss';
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 22px 18px 33px;

  @media screen and (min-width: $bpSmall) {
    border: 1px solid $offWhite;
    margin: 0;
  }

  a {
    height: 100%;
    width: 100%;
  }

  svg {
    height: 7px;
    width: 10px;
    transform: rotate(-90deg);
  }
}

.range-button {
  composes: filter-toggle from '../search/style.module.scss';
}
