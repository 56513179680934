@import 'src/constants/index.scss';

.container {
  color: $darkGrey;
  margin: 150px auto 0;
  max-width: 1440px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1460px) {
    padding: 0 15px;
  }
}
