@import 'src/constants/index.scss';

.get-in-touch {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 70px;

  @media screen and (min-width: $bpMedium) {
    flex-direction: row;
    height: 690px;
    margin: 70px auto 100px auto;
  }
}

.info,
.form {
  height: 100%;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: $bpMedium) {
    width: 50%;
  }
}

.info {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  display: flex;
  align-items: center;
  height: 631px;

  @media screen and (min-width: $bpMedium) {
    height: inherit;
    width: 50%;
  }

  div {
    width: 100%;
    padding-left: 6vw;

    @media screen and (min-width: $bpMedium) {
      padding-top: 60px;
    }
  }

  h2 {
    margin-bottom: 31px;
    font-size: 60px;
    line-height: 70px;
  }

  span,
  a {
    font-size: 18px;
    font-family: $medium;
    line-height: 22px;

    @media screen and (min-width: $bpMedium) {
      font-size: 35px;
      line-height: 40px;
    }
  }

  span {
    display: block;
  }

  address {
    font-style: normal;
    margin-top: 22px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  position: relative;

  @media screen and (min-width: $bpMedium) {
    padding-top: 0;
    background: $offWhite;
  }

  .title {
    display: block;
    position: relative;
    flex: 105px;

    @media screen and (min-width: $bpSmall) {
      flex: 182px;
      padding-top: 86px;
    }

    @media screen and (min-width: $bpMedium) {
      display: none;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 14px 0 37px 0;

    @media screen and (min-width: $bpMedium) {
      line-height: 30px;
    }
  }
}

.mobile {
  display: block;

  button {
    margin-top: 31px;
  }

  @media screen and (min-width: $bpSmall) {
    display: none;
  }
}

.confirmation {
  composes: confirmation from '../forms/signup-form/style.module.scss';
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  padding: 0px 4vw;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: $bpMedium) {
    padding-left: 34px;
    height: 250px;
  }
}

.error {
  padding: 0px 4vw;

  @media screen and (max-width: $bpMedium) {
    padding-left: 34px;
  }
}
