@import 'src/constants/index.scss';

.product-featured {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: -249px;
  height: 399px;
  max-width: 1362px;
  width: 100%;
  padding-right: 69px;

  @media screen and (max-width: $bpXXLarge) {
    justify-content: center;
    padding-right: 0;
  }

  @media screen and (max-width: $bpXLarge) {
    left: 0;
    bottom: -299px;
    margin-bottom: 45px;
    padding-right: 30px;
    max-width: 100%;
  }

  @media screen and (max-width: $bpMedium) {
    padding-left: 30px;
    padding-right: 15px;
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    padding: 0;
    margin-right: 0;
    height: auto;
    bottom: -454px;
    position: relative;
  }
}

.copy {
  align-self: flex-end;
  color: $darkGrey;
  margin-bottom: 64px;
  padding-right: 68px;
  max-width: 490px;
  text-align: right;

  @media screen and (max-width: $bpXXLarge) {
    padding-right: 38px;
    margin-bottom: 25px;
    width: 35%;
  }

  @media screen and (max-width: $bpXLarge) {
    width: 50%;
  }

  @media screen and (max-width: $bpMedium) {
    margin-bottom: 35px;
  }

  @media screen and (max-width: 795px) {
    width: 54%;
    text-align: left;
    margin-bottom: 10px;
  }

  @media screen and (max-width: $bpSmall) {
    margin-bottom: 60px;
    margin-right: 25px;
    padding-right: 0;
  }

  @media screen and (max-width: 675px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 377px;
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    max-width: 323px;
  }

  @media screen and (max-width: 360px) {
    padding-left: 22px;
  }

  .title {
    @media screen and (max-width: 675px) {
      padding-top: 35px;
    }

    @media screen and (max-width: 540px) {
      max-width: 360px;
    }

    @media screen and (max-width: $bpXSmall) {
      padding-left: 15px;
    }

    h2 {
      color: $darkGrey;
      margin-bottom: 0;
    }
  }

  p {
    text-align: right;
    margin-top: 28px;

    @media screen and (max-width: $bpXXLarge) {
      text-align: left;
    }

    @media screen and (max-width: $bpXLarge) {
      text-align: right;
    }

    @media screen and (max-width: $bpSmall) {
      text-align: left;
    }
  }
}

.product {
  @media screen and (max-width: 1350px) {
    align-self: center;
  }

  @media screen and (max-width: $bpXLarge) {
    align-self: flex-end;
  }

  @media screen and (max-width: $bpMedium) {
    margin-left: 0;
  }

  @media screen and (max-width: 675px) {
    margin-left: 0;
    align-self: center;
  }

  .wrapper {
    height: 399px;
    width: 399px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: $bpXXLarge) {
      height: 350px;
      width: 350px;
    }

    @media screen and (max-width: $bpMedium) {
      height: 318px;
      width: 318px;
    }

    @media screen and (max-width: $bpXSmall) {
      height: 280px;
      width: 280px;
    }

    img {
      height: 100%;
      width: 100%;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }
    }

    span {
      color: $white;
      font-size: 24px;
      line-height: 42px;
      position: absolute;
      bottom: 17px;
      left: 29px;
    }
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(107, 114, 148, 0) 0%,
    rgba(57, 62, 91, 0.2) 98%
  );
}
