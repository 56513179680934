.overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-dialog {
  border-radius: 20px;
  border: 4px solid white;
  min-height: 230px;
  display: flex;
  align-items: center;
  max-width: 700px;
  background: rgb(242, 217, 207);
  background: linear-gradient(
    180deg,
    rgba(242, 217, 207, 1) 41%,
    rgba(255, 255, 255, 1) 81%
  );
  box-shadow: 5px 5px 45px 4px rgba(0, 0, 0, 0.15);
  > div {
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: 5px 20px;
  }
}
.content {
  padding: 50px;
  padding-left: 250px;
  p {
    font-size: 16px;
    opacity: 0.9;
  }
  > div {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    button {
      padding: 10px;
      width: 40%;
      margin-right: 5%;
    }
  }
}
