@import 'src/constants/index.scss';

.people-grid {
  padding: 0;
  margin-top: 100px;

  @media screen and (min-width: $bpLarge) {
    margin-top: 142px;
    margin-bottom: 50px;
  }

  .grid {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $bpLarge) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      column-gap: 10px;
      grid-row-gap: 10px;
      row-gap: 10px;
    }

    .person {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: $bpLarge) {
        align-items: center;
        flex-direction: row;
      }

      span {
        color: $white;
        text-transform: lowercase;
        font-size: 35px;
        font-weight: $medium;
        line-height: 45px;
        transform: rotate(-90deg);
        position: absolute;
        bottom: 205px;
        width: 405px;
        display: none;

        @media screen and (min-width: $bpLarge) {
          display: block;
          opacity: 1;
          transition: opacity 0.3s;
        }
      }
    }

    .description-container {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 30px 11px;

      @media screen and (min-width: $bpLarge) {
        align-items: center;
        color: $white;
        padding: 0 76px;
        position: absolute;
        text-align: center;
      }

      h3,
      .job {
        text-transform: lowercase;
      }

      h3,
      .job,
      .description {
        display: block;

        @media screen and (min-width: $bpLarge) {
          display: none;
        }
      }

      .description {
        margin-top: 27px;
        max-width: 490px;
      }
    }
  }

  .grid-item {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }

    &:nth-child(even) {
      margin-right: 19px;

      .description-container {
        padding: 30px;
      }
    }

    &:nth-child(odd) {
      margin-left: 19px;
    }

    @media screen and (min-width: $bpLarge) {
      &:nth-child(even) {
        margin-top: -33px;
        margin-right: 0;

        span {
          left: -160px;
        }

        .description-container {
          padding: 0 76px;
        }
      }

      &:nth-child(odd) {
        margin-left: 0;

        span {
          right: -160px;
        }

        /* in case the number of people is odd */
        &:last-of-type {
          grid-column: 2 / 2;
          margin-top: -33px;

          span {
            right: initial;
            left: -160px;
          }
        }
      }

      &:hover {
        .overlay {
          display: block;
          background: linear-gradient(
            180deg,
            rgba(107, 114, 148, 0.72) 0%,
            rgba(57, 62, 91, 0.72) 100%
          );
          position: absolute;
          width: 100%;
          height: 100%;
          animation: slide-up 0.4s ease-in forwards;
        }

        h3,
        .job,
        .description {
          display: block;
          animation: slide-up 0.3s ease-in forwards;
        }

        span {
          opacity: 0;
        }
      }
    }
  }
}
