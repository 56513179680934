@import 'src/constants/index.scss';

.footer {
  background: rgba($offWhite, 0.8);
  padding: 30px 0;
  position: relative;
  min-height: 465px;
  width: 100%;

  .wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 115px 52px 115px;

    @media screen and (max-width: 1200px) {
      padding-right: 65px;
      padding-left: 65px;
    }

    @media screen and (max-width: 785px) {
      flex-wrap: wrap;
      padding: 0 30px 75px 30px;
    }

    h3 {
      font-size: 15px;
      line-height: 22px;
      padding-bottom: 19px;
    }

    a {
      color: $darkBlue;
    }
  }

  .inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;

    @media screen and (max-width: 720px) {
      min-width: 100%;
    }
  }

  .copyright {
    background: $grey;
    color: $darkBlue;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $bpSmall) {
      padding: 22px 44px 0 44px;
    }

    @media screen and (max-width: $bpXSmall) {
      padding: 22px 30px 0 30px;
      height: 77px;
    }

    p {
      font-size: 11px;
      line-height: 14px;

      a {
        text-decoration: underline;
        margin-left: 4px;
      }
    }
  }
  .made-in-britain {
    width: 46px;
    margin-top: 50px;
  }
}
