@import 'src/constants/index.scss';

.midi-intro {
  padding: 0 7px 0 70px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $bpSmall) {
    padding: 0px 25px 0px 25px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }
}

.midi-belowinteractive {
  margin-top: -100px;
  @media screen and (max-width: 779px) {
    margin-top: 0;
  }
  margin-bottom: 100px;
}

.midi-bgtext {
  position: absolute;
  height: 55vw;
  z-index: 200;
  margin-top: -9vw;
  margin-left: -110px;
}

.midi-footer {
  width: 100%;
  margin-top: 30px;
  > div {
    width: 100%;
    height: 700px;
    background: #eedad1;
    display: flex;
    border-top: 88px solid white;
    padding-bottom: 109px;
    > div:nth-child(1) {
      width: 50%;
      img {
        width: 100%;
        height: 117%;
        object-fit: cover;
        margin-top: -100px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 62px;
    }
    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
      > div:nth-child(1) {
        width: 100%;
        padding-right: 50px;
      }
      > div:nth-child(2) {
        padding: 30px;
      }
    }
  }
}

.floatingimage {
  padding-left: 11%;
  position: absolute;
}

.midi-carbolite {
  button {
    margin-top: 50px;
  }
}
