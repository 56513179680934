@import 'src/constants/index.scss';

.description {
  display: none;

  @media screen and (min-width: $bpMedium) {
    display: block;
    background-color: white;
  }

  div {
    display: flex;
    justify-content: flex-end;
    padding: 99px 33px 74px 20px;
    position: relative;
    margin: 0 auto;

    &::before {
      content: '';
      background-color: $white;
      height: 60px;
      width: 60px;
      position: absolute;
      top: -30px;
      right: 50%;
      transform: translateX(50%) rotate(45deg);
    }

    h2 {
      text-transform: lowercase;
      margin-right: 75px;

      @media screen and (min-width: $bpXXLarge) {
        margin-right: 130px;
      }
    }

    ul {
      font-size: 30px;
      font-family: $medium;
      line-height: 45px;
      width: 54%;

      @media screen and (min-width: $bpXXLarge) {
        width: 50%;
        font-size: 35px;
      }

      li::first-letter {
        text-transform: lowercase;
      }
    }
  }
}
