@import 'src/constants/index.scss';

.news-hero {
  display: flex;
  justify-content: flex-end;
  margin-top: 110px;
  padding-top: 0;
  padding-left: 25px;

  @media screen and (max-width: $bpXLarge) {
    margin-top: 80px;
  }

  @media screen and (max-width: $bpLarge) {
    padding-left: 0;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1335px;

    @media screen and (max-width: $bpLarge) {
      flex-direction: column-reverse;
      overflow: hidden;
    }

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
    }

    .image-wrapper {
      max-height: 638px;
      overflow: hidden;
      position: relative;
      z-index: -1;

      @media screen and (max-width: $bpLarge) {
        max-width: 100%;
      }

      @media screen and (max-width: 910px) {
        margin: 0;
      }

      @media screen and (max-width: 700px) {
        height: 475px;
      }

      @media screen and (min-width: $bpSmall) {
        order: 2;
      }

      img {
        @media screen and (max-width: 1380px) {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .overlay {
      @media screen and (max-width: $bpLarge) {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16) 0%,
          rgba(255, 255, 255, 0.02) 22%
        );
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.text-container {
  margin-top: -22px;

  @media screen and (min-width: $bpSmall) {
    margin-top: -32px;
  }

  @media screen and (min-width: $bpLarge) {
    margin-top: -40px;
    max-width: 33%;
  }
}

.text-wrapper {
  padding-left: 25px;
  padding-right: 20px;

  p {
    font-family: $regular;
    font-size: 18px;
    margin-top: 17px;
    padding-left: 2px;

    @media screen and (min-width: $bpSmall) {
      margin-top: 33px;
    }
  }
}

.title {
  font-size: 35px;
  line-height: 40px;
  max-width: 95%;
  text-transform: lowercase;

  @media screen and (min-width: $bpSmall) {
    font-size: 60px;
    line-height: 65px;
    max-width: 100%;
  }

  @media screen and (min-width: $bpLarge) {
    padding-top: 111px;
  }
}
