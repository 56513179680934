@import 'src/constants/index.scss';

.parallax-wrapper {
  /* height: 115vh; */
  position: relative;
  background: rgb(246, 192, 173);
  background: linear-gradient(
    0deg,
    rgba(246, 192, 173, 1) 38%,
    rgba(40, 44, 49, 0) 96%
  );
  overflow: hidden;

  > img {
    object-fit: cover;
    width: 100%;
    object-position: bottom;
    position: relative;
    bottom: 0;
    margin-bottom: -25%;
    margin-top: 20%;
    @media screen and (max-width: $bpSmall) {
      width: 161%;
    }
  }

  @media screen and (max-width: $bpSmall) {
    height: 115vh;
    > img {
      position: absolute;
      margin-bottom: -40%;
    }
  }
}

.middle-text {
  height: inherit;
  /* background-color: black; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vw;
  max-height: 500px;
  h3 {
    margin-top: 20%;
  }
}
