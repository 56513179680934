@import 'src/constants/index.scss';

.engine-selector {
  background-color: #e2e2e7;
  padding: 80px 12px 42px 13px;
  position: relative;

  &::after {
    content: '';
    height: 129px;
    width: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #e2e2e7 100%);
    position: absolute;
    top: -129px;
    left: 0;
    z-index: -1;
  }

  @media screen and (min-width: $bpMedium) {
    padding-bottom: 101px;
  }

  @media screen and (min-width: $bpXLarge) {
    padding-top: 96px;
  }
}

.main-wrapper {
  .cta {
    display: flex;
    align-items: center;
    background: transparent;
    margin-bottom: 40px;

    @media screen and (min-width: $bpXLarge) {
      margin-bottom: 54px;
      padding: 0;
    }

    h2 {
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      width: 100%;

      @media screen and (min-width: $bpXLarge) {
        text-align: center;
        font-size: 35px;
      }
    }
  }
}

.variant-anchor {
  display: none;

  @media screen and (min-width: $bpSmall) {
    cursor: default;
    display: block;
    position: fixed;
    top: 60vh;
    left: -68px;
    font-size: 22px;
    line-height: 31px;
    transform: rotate(-90deg);
    text-align: right;
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 100;
    width: 350px;
    left: -135px;
  }

  @media screen and (min-width: $bpLarge) {
    left: -113px;
  }

  @media screen and (min-width: $bpXLarge) {
    top: 80%;
  }

  span {
    cursor: pointer;
    text-transform: lowercase;

    span {
      color: $white;
      border-radius: 5px;
      background: $salmonGrad;
      padding: 0 8px 2px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &.scroll {
    opacity: 1;
  }

  &.absolute {
    position: absolute;
    opacity: 1;
    top: 258px;
    pointer-events: none;
  }
}

.options {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  max-width: 804px;
  margin: 0 auto;
  z-index: 1;

  &.centered {
    justify-content: center;
  }

  @media screen and (min-width: $bpMedium) {
    justify-content: flex-start;
  }

  @media screen and (min-width: $bpXXLarge) {
    max-width: 1074px;
  }
}

.engine {
  background: $white;
  border-radius: 4px;
  margin-top: 2px;
  padding: 15px 32px 16px 35px;
  position: relative;
  width: 100%;
  transition: opacity 0.3s;
  will-change: opacity;

  @media screen and (min-width: $bpSmall) {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 70%;
  }

  @media screen and (min-width: $bpMedium) {
    opacity: 0.5;
    padding: 25px 16px 26px 35px;
    max-width: 48%;
    min-height: 141px;
    margin-top: 10px;
  }

  @media screen and (min-width: $bpXXLarge) {
    max-width: 348px;
    display: flex;
  }

  &::after {
    content: '';
    height: 4px;
    width: 100%;
    background: $salmonGrad;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    will-change: opacity;
  }

  svg {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;

    circle {
      @media screen and (max-width: 1150px) {
        fill: $salmon;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-family: $medium;
    text-align: left;
    text-transform: lowercase;

    @media screen and (min-width: $bpMedium) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  ul {
    font-size: 16px;
    line-height: 20px;
    font-family: $regular;
    margin-top: 10px;
    opacity: 0.7;
    text-align: left;
    height: 0;
    opacity: 0;
    margin-top: 0;

    @media screen and (min-width: $bpMedium) {
      height: auto;
      opacity: 0.7;
      margin-top: 10px;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;

    &::after {
      opacity: 0.8;
    }
  }

  &.active {
    opacity: 1;

    p {
      font-size: 18px;
      line-height: 20px;
    }

    ul {
      height: auto;
      opacity: 0.7;
      margin-top: 10px;
    }

    &::after,
    svg {
      opacity: 1;
    }
  }
}
