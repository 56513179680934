@import 'src/constants/index.scss';
@import 'components/filters/title-style.module.scss';

.filter-option {
  margin: 0 10px;

  @media screen and (max-width: $bpSmall) {
    background: $white;
    border: 1px solid $offWhite;
    padding: 0 22px 0 33px;
    margin: 0;
    width: 100%;
  }

  .title {
    justify-content: center;

    @media screen and (max-width: $bpSmall) {
      justify-content: space-between;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $bpSmall) {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 278px;
    }

    @media screen and (min-width: $bpLarge) {
      justify-content: initial;
    }
  }

  li {
    color: rgba($black, 0.5);
    align-items: center;
    margin: 10px 5px 20px 5px;
    min-width: 82px;
    max-width: 82px;

    @media screen and (max-width: $bpSmall) {
      margin: 3px 0;

      &:last-of-type {
        padding-bottom: 5px;
      }
    }

    span {
      display: inline-block;
      line-height: 12px;
      opacity: 0.5;

      &.is-checked {
        opacity: 1;
      }
    }
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: $bpSmall) {
      flex-direction: column;
    }

    span {
      @media screen and (min-width: $bpSmall) {
        margin-top: 5px;
      }
    }
  }
}

label.is-disabled {
  text-decoration: line-through;
  cursor: default;
}
