@import 'src/constants/index.scss';

.mobile-menu-wrapper {
  background: $darkGrey;
  color: $white;
  height: 100vh;
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.5s ease-in-out;
  will-change: width;
  overflow: hidden;

  &.slide-in {
    width: 100%;
    overflow-y: scroll;
  }

  @media screen and (min-width: $bpXLarge) {
    display: none;
  }

  .logo {
    text-align: center;
    margin-bottom: 56px;
    margin-top: 22px;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    a {
      display: inline-block;
      position: relative;
      height: 40px;
      width: 176px;
    }

    &.fade-in {
      opacity: 1;
    }

    @media screen and (min-width: 360px) and (max-height: 641px) {
      margin-bottom: 46px;
    }

    @media screen and (min-width: 320px) and (max-height: 568px) {
      margin-bottom: 26px;
    }
  }

  nav {
    margin-bottom: 30px;
  }

  ul {
    padding: 0px 19px 0 27vw;

    li {
      cursor: pointer;
      padding: 10px 0;
      margin-top: 15px;

      @media screen and (min-width: 320px) and (max-height: 568px) {
        margin-top: 6px;
      }

      &.company {
        display: flex;
        justify-content: space-between;

        span {
          font-size: $a;

          @media screen and (min-width: 741px) {
            font-size: 22px;
          }
        }

        &.is-open {
          color: $salmon;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row-reverse;
          margin: 0;

          .arrow {
            transform: rotate(180deg);
            margin-left: -80px;
            margin-right: 22px;
          }
        }
      }

      .arrow {
        fill: $salmon;
        height: 20px;
        width: 58px;

        @media screen and (min-width: 741px) {
          margin-right: 100px;
        }
      }

      a {
        font-size: $a;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 741px) {
          font-size: 22px;
        }
      }

      &:hover,
      &:active {
        span {
          color: $salmon;
        }
      }
    }
  }
}
