@import 'src/constants/index.scss';

.sub-menu {
  &.is-open {
    padding: 0;
  }

  li {
    cursor: pointer;
    color: white;
    padding: 10px 0;
    margin-top: 30px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .arrow {
    fill: $salmon;
    height: 20px;
    width: 58px;

    @media screen and (min-width: 741px) {
      margin-right: 100px;
    }
  }
}
