@import 'src/constants/index.scss';

.main-filter {
  margin: 0 auto;
  text-align: center;
  height: auto;
  width: 100%;
  position: relative;

  @media screen and (max-width: $bpSmall) {
    text-align: left;
  }

  .centered {
    text-align: center;
  }
}

.filter-options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 17px 200px 3px 200px;
  opacity: 1;

  @media screen and (max-width: 1380px) {
    padding: 17px 84px;
  }

  @media screen and (max-width: $bpXXLarge) {
    padding: 17px 45px;
  }

  @media screen and (max-width: $bpSmall) {
    flex-direction: column;
    padding: 0;
  }

  &.hide {
    display: none;
    opacity: 0;
  }

  label {
    font-family: $regular;
    font-size: 12px;

    @media screen and (max-width: $bpSmall) {
      display: flex;
      align-items: center;
    }

    span {
      @media screen and (max-width: $bpSmall) {
        padding-left: 10px;
        min-width: 98px;
      }
    }
  }
}

.close {
  cursor: pointer;
  color: $white;
  font-size: 16px;
  font-family: $regular;
  display: inline-block;
  position: relative;
  transition: opacity 0.3s;
  background: $midGrey;
  width: 55px;
  height: 55px;

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 27px;
    background: $white;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(-45deg);
  }

  &::after {
    transform: translateX(-50%) rotate(45deg);
  }

  &:hover {
    opacity: 0.5;
  }

  svg {
    fill: $white;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.reset {
  padding: 0;
  color: $midGrey;
  font-size: 18px;
  line-height: 20px;
  height: 28px;
  border-bottom: 1px solid $midGrey;
  margin-right: 50px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    cursor: default;
    color: $grey;
    border-bottom-color: $grey;
    pointer-events: none;
  }
}
