@import 'src/constants/index.scss';

.btn {
  font-size: 16px;
  font-family: $regular;
  height: 62px;
  width: 236px;
  border-radius: 33px;
  border: 1px solid transparent;
  border: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: opacity 0.3s;
  z-index: 100;

  &:enabled {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &:focus {
    outline: 0;
  }

  &.small {
    height: 50px;
    width: 73px;

    @media screen and (max-width: $bpSmall) {
      height: 50px;
      width: 70px;
    }
  }

  &.default {
    height: 50px;
    width: 204px;
  }

  &.medium {
    height: 50px;
    width: 247px;
  }

  &.big {
    font-size: 20px;
    height: 62px;
    width: 236px;
  }

  &.dark {
    background-color: $darkBlue;
    color: $white;
    transition: opacity 0.3s;

    &:disabled {
      background-color: $grey;
      cursor: default;
    }
  }

  &.dark-transparent {
    background-color: transparent;
    color: $darkBlue;
    border: 2px solid $darkBlue;
    transition: opacity 0.3s;
  }

  &.centralize {
    margin: 0 auto;
  }
}
