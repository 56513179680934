@import 'src/constants/index.scss';

.filters {
  background: #fcfcfc;
  margin: 0 auto;
  text-align: center;
  position: relative;
  width: 100%;
}

.results {
  font-size: 18px;
  line-height: 23px;
  background: $midGrey;
  padding: 18px 0;
  color: white;
  position: sticky;
  bottom: 0;
  left: 0;

  @media screen and (min-width: $bpSmall) {
    font-family: $medium;
    font-size: 20px;
    line-height: 32px;
    padding: 9px 0;
  }

  svg {
    height: 30px;
    width: 30px;
    fill: $offWhite;
    animation: fill-anim 1s infinite ease-in-out;

    :global(#prefix__radial-gradient-icon) {
      stop {
        stop-color: $offWhite;
      }
    }

    @media screen and (min-width: $bpXLarge) {
      position: relative;
    }
  }
}
