@import 'src/constants/index.scss';

.range {
  margin-bottom: 47px;

  @media screen and (min-width: $bpMedium) {
    margin-bottom: 0px;
  }
}

.wrapper {
  max-width: 1255px;
  margin: 0 auto;
  padding-top: 19px;
  position: relative;

  @media screen and (min-width: $bpMedium) {
    padding-bottom: 32px;
    padding-top: 10px;
  }

  h2 {
    padding-left: 34px;
  }
}

// once we are able to update ProductFamilies styles
// this class should be deleted
.container {
  margin-top: -180px;
}
