@import 'src/constants/index.scss';

.product-grid {
  padding: 0;
  margin-bottom: -50px;

  h3 {
    text-align: center;
    margin: 21px auto 23px auto;

    @media screen and (min-width: $bpLarge) {
      margin: 38px auto;
    }
  }
}

.grid {
  display: block;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    '. . . .'
    '. . Feature-1 Feature-1'
    '. . Feature-1 Feature-1'
    '. . . .' 'Feature-2 Feature-2 . .'
    'Feature-2 Feature-2 . .'
    '. . . .'
    '. Feature-3 Feature-3 .'
    '. Feature-3 Feature-3 .'
    '. . . .'
    '. . Feature-4 Feature-4'
    '. . Feature-4 Feature-4'
    '. . . .'
    '. . Feature-5 Feature-5'
    '. . Feature-5 Feature-5'
    '. . . .'
    'Feature-6 Feature-6 . .'
    'Feature-6 Feature-6 . .'
    '. . . .'
    '. . . .';

  @media screen and (min-width: 568px) {
    display: grid;
  }

  .feature-1 {
    grid-area: Feature-1;
  }

  .feature-2 {
    grid-area: Feature-2;
  }

  .feature-3 {
    grid-area: Feature-3;
  }

  .feature-4 {
    grid-area: Feature-4;
  }

  .feature-5 {
    grid-area: Feature-5;
  }

  .feature-6 {
    grid-area: Feature-6;
  }

  .feature-7 {
    grid-area: Feature-7;
  }

  .grid-item {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;

    &.feature-1,
    &.feature-2,
    &.feature-3,
    &.feature-4,
    &.feature-5,
    &.feature-6,
    &.feature-7 {
      max-height: 952.5px;

      @media screen and (min-width: 1921px) {
        max-width: 100%;
        max-height: 100%;
      }
    }

    video {
      display: block;
      object-fit: cover;
      width: 100%;
    }

    img {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    @media screen and (min-width: $bpXLarge) {
      max-height: 476.25px;

      &.feature-item {
        a {
          &:hover {
            .description-container {
              figcaption {
                font-size: 60px;
                line-height: 64px;
                margin-bottom: 12px;

                @media screen and (min-width: 1560px) {
                  font-size: 120px;
                  line-height: 126px;
                  margin-bottom: 20px;
                }
              }
              .description {
                display: block;
                font-weight: $regular;
                max-width: 60%;

                @media screen and (min-width: 1560px) {
                  max-width: 32%;
                }
              }
            }
          }
        }
      }

      a {
        width: 100%;
        height: 100%;

        &:hover {
          .overlay {
            display: block;
            background: linear-gradient(
              180deg,
              rgba(107, 114, 148, 0.72) 0%,
              rgba(57, 62, 91, 0.72) 100%
            );
            position: absolute;
            width: 100%;
            height: 100%;
            animation: slide-up 0.4s ease-in forwards;
          }

          .description-container {
            svg {
              display: none;
            }

            figcaption {
              font-size: 30px;
              line-height: 34px;
              max-width: 90%;
              margin-bottom: 5px;
              animation: slide-up 0.4s ease-in forwards;

              @media screen and (min-width: 1560px) {
                font-size: 60px;
                line-height: 70px;
              }
            }

            .spec {
              display: none;
            }

            .description {
              display: block;
              font-weight: $regular;
              max-width: 90%;
              animation: slide-up 0.3s ease-in forwards;

              @media screen and (min-width: 1560px) {
                max-width: 64%;
              }
            }
          }
        }

        .bottom-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(107, 114, 148, 0) 0%,
            rgba(57, 62, 91, 0.2) 98%
          );
        }
      }
    }

    @media screen and (min-width: 1921px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .description-container {
    position: absolute;
    left: 20px;
    bottom: 10px;
    color: $white;

    @media screen and (min-width: 768px) {
      left: 26px;
      bottom: 16px;
    }

    svg {
      margin-bottom: -10px;
      height: 20px;
      width: 41px;
    }

    figcaption {
      font-size: 28px;
      font-weight: $medium;
      line-height: 32px;
      padding-top: 10px;

      // mobile landscape
      @media screen and (min-width: 568px) and (max-height: $bpSmall) and (orientation: landscape) {
        font-size: 22px;
        line-height: 26px;
        padding-right: 5px;
      }

      @media screen and (min-width: 768px) {
        padding-top: 5px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 35px;
        line-height: 42px;
      }
    }

    .spec {
      font-size: 16px;
      font-weight: $regular;
      line-height: 38px;

      @media screen and (min-width: 1280px) {
        font-size: 20px;
        max-width: 350px;
        line-height: 42px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 20px;
      font-weight: $regular;
      display: none;

      @media screen and (min-width: $bpXLarge) {
        font-size: 18px;
        line-height: 24px;
      }

      @media screen and (min-width: 1560px) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

.filtered {
  // plus results bar height
  margin-top: calc(144px + 60px);

  @media screen and (min-width: $bpSmall) {
    margin-top: calc(136px + 60px);
  }

  @media screen and (min-width: $bpLarge) {
    margin-top: calc(196px + 49px);
  }
}

.range-nav-hidden {
  // plus results bar height
  margin-top: calc(144px + 60px);

  @media screen and (min-width: $bpSmall) {
    margin-top: calc(136px + 60px);
  }

  @media screen and (min-width: $bpXLarge) {
    margin-top: calc(136px + 49px);
  }
}

.remove-margin {
  margin-top: 0;
}
