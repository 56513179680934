@import 'src/constants/index.scss';

.slider {
  width: 100vw;
  height: 80vw;
  max-width: 965px; // must respect image width
  max-height: 600px; // must respect image height
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    @media screen and (min-width: $bpSmall) {
      color: white;
      font-size: 45px;
      position: absolute;
      transform: rotate(-90deg);
      transition: opacity 0.2s;
      z-index: 1;
    }
  }

  &::before {
    @media screen and (min-width: $bpSmall) {
      content: 'traditional LED 93 CRI';
      top: 45%;
      left: -170px;
    }
  }

  &::after {
    @media screen and (min-width: $bpSmall) {
      content: 'origin natural';
      top: 30%;
      right: -90px;
    }
  }

  figure {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;

    div {
      //divisor
      &:first-of-type {
        position: absolute;
        height: 100%;
        width: 50%;
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
        bottom: 0;

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: -2px;
          width: 3px;
          height: 50%;
          background: white;
          z-index: 3;

          @media screen and (min-width: $bpMedium) {
            width: 4px;
          }
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
      }

      // handler
      &:last-of-type {
        border-radius: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 30px;
        width: 30px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;

        @media screen and (min-width: $bpMedium) {
          height: 50px;
          width: 50px;
        }

        svg {
          fill: $blue;
          height: 4px;
          width: 7px;

          @media screen and (min-width: $bpMedium) {
            height: 8px;
            width: 13px;
          }

          &:first-of-type {
            transform: rotate(90deg);
            margin-right: 4px;
          }

          &:last-of-type {
            transform: rotate(-90deg);
            margin-left: 4px;
          }
        }
      }
    }
  }

  input[type='range'] {
    cursor: grab;
    appearance: none;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    width: calc(100% + 50px);
    z-index: 2;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
  }

  input[type='range']::-moz-range-track {
    appearance: none;
    height: 15px;
    width: 100%;
    background-color: transparent;
    position: relative;
    outline: none;
  }
}

.bg-color {
  background: $salmonGrad;
  position: relative;
  height: 13px;
  width: 100%;
  z-index: 3;
}

.hide-left {
  &::before {
    opacity: 0;
  }
}

.hide-right {
  &::after {
    opacity: 0;
  }
}
