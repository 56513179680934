@import 'src/constants/index.scss';

.hero-title {
  text-align: left;
  font-size: 60px;
  line-height: 62px;
  margin-top: 7px;

  @media screen and (min-width: $bpLarge) {
    font-size: 89px;
    line-height: 90px;
  }

  @media screen and (min-width: $bpXLarge) {
    text-align: right;
    text-transform: lowercase;
  }
}

.font-smaller {
  @media screen and (max-width: $bpSmall) {
    font-size: 50px;
    line-height: 52px;
  }

  @media screen and (max-width: $bpXSmall) {
    font-size: 40px;
    line-height: 42px;
  }
}

.dark {
  color: $darkGrey;

  @media screen and (min-width: $bpLarge) {
    color: $white;
  }
}

.light {
  color: $darkGrey;
}
