@import 'src/constants/index.scss';

.four-oh-four {
  background: url('../../assets/images/orluna_404.jpg') no-repeat center center;
  background-size: cover;
  height: 1352px;
  width: 100%;
  padding: 0 10%;

  @media screen and (max-width: $bpLarge) {
    height: 1000px;
  }

  @media screen and (max-width: $bpSmall) {
    height: 800px;
  }

  .content {
    height: 100%;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 125px;

    @media screen and (max-width: $bpLarge) {
      padding-top: 0;
    }

    @media screen and (max-width: 360px) {
      margin-top: -24px;
    }

    h2 {
      color: $white;
      font-size: 120px;
      line-height: 140px;
      text-align: center;

      @media screen and (max-width: $bpLarge) {
        font-size: 80px;
        line-height: 98px;
      }

      @media screen and (max-width: $bpXSmall) {
        font-size: 60px;
        line-height: 78px;
      }
    }

    h4 {
      color: $white;
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      font-size: 35px;
      line-height: 45px;
      margin: 97px 0;

      @media screen and (max-width: $bpSmall) {
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        margin: 45px 0;
      }
    }
  }
}
