@import 'src/constants/index.scss';

.range-navigation {
  display: none;
  background-color: $blue;
  color: $white;
  margin-top: 86px;
  width: 100%;

  @media screen and (min-width: $bpLarge) {
    display: block;
    margin-top: 86px;
  }

  @media screen and (min-width: $bpXLarge) {
    margin-top: 75px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    // 1480px instead 1440px because of the side padding
    max-width: 1480px;

    a {
      display: inline-block;
      font-size: 16px;
      line-height: 20px;
      padding: 5px;
      margin: 0 5px;
      position: relative;
    }
  }
}

.product-detail {
  opacity: 0;
  transform: translateY(-100%);
  will-change: transform, opacity;
}

.is-visible {
  transition: transform 0.5s, opacity 0.5s;
  transform: translateY(0);
  opacity: 1;
}
