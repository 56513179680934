@import 'src/constants/index.scss';

.featured-content {
  margin-top: 45px;
  margin-bottom: 88px;
  position: relative;

  @media screen and (min-width: $bpMedium) {
    margin-top: 68px;
    margin-bottom: 66px;
    padding-left: 2%;
  }

  @media screen and (min-width: $bpXXLarge) {
    padding-left: 8%;
  }

  span {
    display: inline-block;

    div {
      display: none;

      @media screen and (min-width: $bpXLarge) {
        display: block;
        top: 500%;
        left: -14%;
        width: 700px;
        background-size: 700px;
        opacity: 0.6;
      }
    }
  }
}

.negative-margin-bottom {
  // because of the vertical-copy line-height
  @media screen and (min-width: $bpXLarge) {
    margin-bottom: -30px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: $bpSmall) {
    flex-direction: row;
    justify-content: center;
  }

  @media screen and (min-width: $bpMedium) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.landscape {
  margin: 0;
  z-index: 1;

  // callout component
  div {
    margin: 0 auto;
  }

  @media screen and (min-width: $bpMedium) {
    margin-right: 37px;
    width: 58%;
  }
}

.portrait {
  position: relative;
  margin-top: 15px;
  width: 79%;

  @media screen and (min-width: $bpSmall) {
    width: 50%;
  }

  @media screen and (min-width: $bpMedium) {
    margin-top: 64px;
    margin-bottom: 0;
    width: 33%;
  }

  &::after {
    content: '';
    background: $lightDarkBlueGrad;
    height: 95%;
    width: 90%;
    position: absolute;
    right: 0;
    bottom: -34px;
    transform: rotate(-180deg);

    @media screen and (min-width: $bpSmall) {
      bottom: -54px;
      right: -49px;
      width: 100%;
    }
  }

  img {
    position: relative;
    z-index: 1;
  }

  span {
    color: $white;
    font-size: 42px;
    line-height: 42px;
    position: absolute;
    left: 24px;
    top: 50px;
    max-width: 352px;
    z-index: 2;
  }

  // callout component
  div {
    margin: 0 auto;
    z-index: 1;
  }
}

.text-wrapper {
  width: 100%;
  padding-left: 21px;
  padding-right: 17px;
  margin-bottom: 41px;

  @media screen and (min-width: $bpXSmall) {
    padding-left: 34px;
  }

  @media screen and (min-width: $bpMedium) {
    padding-left: 0;
    margin-bottom: 61px;
  }

  h3 {
    margin-bottom: 24px;

    @media screen and (min-width: $bpSmall) {
      margin-bottom: 24px;
    }
  }

  p {
    max-width: 320px;

    @media screen and (min-width: $bpSmall) {
      max-width: 674px;
    }
  }
}

.vertical-copy {
  display: none;
  transform-origin: bottom right;
  right: 0;
  bottom: 65vh;
  transform: rotate(-90deg) translateX(100%);
  text-align: center;
  pointer-events: none;
  position: relative;
  z-index: -1;

  @media screen and (min-width: $bpXLarge) {
    display: block;
  }

  h2 {
    color: $darkGrey;
    font-size: 220px;
    line-height: 110px;
    text-transform: lowercase;
    opacity: 0.06;
    width: 150%;
  }
}
