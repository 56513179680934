@import 'src/constants/index.scss';

.hero {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: auto;
  position: relative;
  justify-content: center;
  align-items: center;

  img,
  video {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .desktop {
    @media screen and (max-width: $bpSmallPlus) {
      display: none;
    }
  }

  .mobile {
    @media screen and (min-width: $bpSmallPlus) {
      display: none;
    }
  }

  .content-wrapper {
    z-index: 1;

    h1 {
      font-size: 50px;
      line-height: 55px;

      @media screen and (min-width: $bpXSmall) {
        font-size: 60px;
        line-height: 65px;
      }

      @media screen and (min-width: $bpSmall) {
        font-size: 120px;
        line-height: 140px;
      }

      &.dark {
        color: $white;
      }

      &.light {
        color: $darkGrey;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    &.dark {
      color: $white;
    }

    &.light {
      color: $darkGrey;
    }
  }
}

.home {
  img,
  video,
  h1,
  .content-wrapper {
    height: 103vh;

    @media screen and (min-width: 750px) {
      height: 85vh;
    }
  }

  h1 {
    padding-bottom: 11vh;
    padding-left: 22px;
    max-width: 300px;
    display: flex;
    align-items: center;

    @media screen and (min-width: $bpMedium) {
      padding-left: 100px;
    }

    @media screen and (min-width: $bpXLarge) {
      max-width: initial;
      padding-left: 180px;
      padding-bottom: 15vh;
    }
  }
}

.sustainability {
  img,
  video,
  h1,
  .content-wrapper {
    height: 103vh;

    @media screen and (min-width: 750px) {
      height: 85vh;
    }
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    > div {
      img {
        position: relative;
        height: 2em;
        object-fit: contain;
        width: auto;
        padding-left: 22px;
        @media screen and (min-width: $bpMedium) {
          padding-left: 22px;
        }

        @media screen and (min-width: $bpXLarge) {
          max-width: initial;
          padding-left: 22px;
        }
      }
    }
  }

  h1 {
    height: auto;
    padding-left: 22px;
    display: block;

    @media screen and (min-width: $bpMedium) {
      padding-left: 22px;
    }

    @media screen and (min-width: $bpXLarge) {
      max-width: initial;
      padding-left: 22px;
    }
  }
}

.midi {
  img,
  video,
  h1,
  .content-wrapper {
    height: 103vh;

    @media screen and (min-width: 750px) {
      height: 100vh;
    }
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    > div {
      img {
        position: relative;
        height: 2em;
        object-fit: contain;
        width: auto;
        padding-left: 22px;
        @media screen and (min-width: $bpMedium) {
          padding-left: 22px;
        }

        @media screen and (min-width: $bpXLarge) {
          max-width: initial;
          padding-left: 22px;
        }
      }
    }
  }

  h1 {
    height: auto;
    padding-left: 22px;
    display: block;

    @media screen and (min-width: $bpMedium) {
      padding-left: 22px;
    }

    @media screen and (min-width: $bpXLarge) {
      max-width: initial;
      padding-left: 22px;
    }
  }
}

.cta {
  composes: btn from '../buttons/style.module.scss';
  background-color: rgba($grey, 0.7);
  font-size: 18px;
  line-height: 1;
  padding: 0 2.7rem;
  position: absolute;
  right: 76px;
  bottom: 76px;
  height: 50px;
  width: auto;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba($grey, 1);
  }
}

.our-story {
  img,
  video,
  h1,
  .content-wrapper {
    height: 106vh;

    @media screen and (min-width: 750px) {
      height: 92vh;
    }
  }

  h1 {
    padding-left: 22px;
    padding-bottom: 11vh;

    @media screen and (min-width: 750px) {
      padding-left: 65px;
      padding-bottom: 14vh;
    }

    @media screen and (min-width: $bpXLarge) {
      padding-left: 125px;
    }
  }
}

.range {
  img,
  video,
  h1,
  .content-wrapper {
    height: 106vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 750px) {
      height: 92vh;
    }
  }

  h1 {
    padding-bottom: 11vh;
    text-align: center;

    @media screen and (min-width: 750px) {
      padding-bottom: 14vh;
    }
  }
}

.contact {
  img,
  video,
  h1,
  .content-wrapper {
    height: 98vh;

    @media screen and (min-width: $bpSmall) {
      height: 73vh;
    }
  }

  h1 {
    padding-top: 60vh;
    padding-left: 33px;
    max-width: 300px;

    @media screen and (min-width: 740px) {
      max-width: 600px;
      padding-top: 40vh;
    }

    @media screen and (min-width: $bpMedium) {
      padding-left: 79px;
    }

    @media screen and (min-width: $bpXLarge) {
      padding-top: 50vh;
      max-width: initial;
    }
  }
}
