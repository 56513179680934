@import 'src/constants/index.scss';

.result {
  background-color: rgba($darkBlue, 0.03);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bpSmall) {
    flex-direction: row;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding: 15px 0;
  flex: 1;

  label {
    div {
      margin-bottom: 0;
    }
  }

  > svg {
    margin: 0 48px;
    display: none;

    @media screen and (max-width: 1460px) {
      margin: 0 28px;
    }
  }

  p {
    font-size: 16px;
    margin-left: 15px;
  }
}

.icon {
  fill: $darkBlue;
  height: 34px;
  width: 24px;
}

.buttons {
  display: flex;
  align-items: stretch;
  min-height: 59px;

  a,
  button {
    flex: 1;
    display: flex;
    align-items: center;
    text-decoration: underline;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
    font-family: $medium;
    will-change: background-color;
    transition: background-color 0.3s;
    padding: 0 25px;
    white-space: nowrap;

    @media screen and (min-width: $bpSmall) {
      font-size: 18px;
    }

    &:focus {
      outline: none;
    }
  }

  a {
    color: $midGrey;
    background: $grey;

    &:hover {
      background-color: rgba($grey, 0.2);
    }

    @media screen and (min-width: $bpSmall) {
      background-color: rgba($darkGrey, 0.1);
    }

    svg {
      height: 13px;
      width: 38px;
    }
  }

  button {
    background: $darkBlue;
    border: none;
    text-align: right;
    color: white;

    &:hover {
      background-color: rgba($darkBlue, 0.2);
    }

    svg {
      height: 27px;
      width: 27px;
    }

    @media screen and (min-width: $bpSmall) {
      background: rgba($darkBlue, 0.07);
      color: $midGrey;
    }
  }

  svg {
    display: none;
    margin-left: 10px;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }
}
