@import 'src/constants/index.scss';

.why-hero {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  margin-top: 86px;

  @media screen and (min-width: $bpXLarge) {
    margin-top: 112px;
    flex-direction: row;
    align-items: center;
  }
}

.title {
  margin-top: -39px;
  margin-left: 33px;

  @media screen and (min-width: $bpSmall) {
    max-width: 340px;
    margin-top: -67px;
    margin-right: 15px;
  }

  @media screen and (min-width: $bpXXLarge) {
    margin-top: 0;
    margin-right: 81px;
  }

  h1 {
    @media screen and (min-width: $bpSmall) {
      font-size: 100px;
      line-height: 100px;
    }

    @media screen and (min-width: $bpXXLarge) {
      font-size: 120px;
      line-height: 120px;
    }
  }
}

.year {
  display: none;

  @media screen and (min-width: $bpSmall) {
    display: block;
    opacity: 0.06;
    font-family: $medium;
    font-size: 74px;
    left: 57%;
    bottom: 58px;
    text-align: right;
    position: absolute;
    width: 300px;
  }

  @media screen and (min-width: $bpLarge) {
    font-size: 94px;
    bottom: 58px;
    width: 380px;
  }

  @media screen and (min-width: $bpXLarge) {
    width: 890px;
    font-size: 220px;
    line-height: 140px;
    transform: rotate(-90deg);
    left: -381px;
    bottom: -6px;
  }
}

.image-wrapper {
  overflow: hidden;
  height: 480px;

  @media screen and (min-width: $bpMedium) {
    height: 638px;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @media screen and (min-width: $bpMedium) {
      height: auto;
    }
  }
}
