@import 'src/constants/index.scss';

.listing-wrapper {
  position: relative;
  overflow: hidden;

  .item {
    &:nth-child(2) {
      margin-bottom: 0;
    }
  }
}

.item {
  margin-bottom: 58px;

  @media screen and (max-width: $bpLarge) {
    margin-bottom: 0;
  }

  @media screen and (max-width: $bpSmall) {
    padding: 20px 0;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $bpMedium) {
    align-items: flex-end;
  }

  @media screen and (max-width: 585px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.image-first {
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media screen and (max-width: $bpSmall) {
    padding: 0 44px 0 0;
  }

  @media screen and (max-width: 585px) {
    flex-direction: column-reverse;
  }

  .text-wrapper {
    margin-left: 80px;

    @media screen and (max-width: 1400px) {
      margin-left: 70px;
      margin-right: 10px;
    }

    @media screen and (max-width: $bpXLarge) {
      margin-left: 45px;
    }

    @media screen and (max-width: 960px) {
      padding-bottom: 20px;
    }

    @media screen and (max-width: $bpSmall) {
      margin-left: 30px;
      margin-right: 0;
    }

    @media screen and (max-width: 640px) {
      padding-left: 0;
      width: 210px;
      align-self: flex-end;
    }

    @media screen and (max-width: 585px) {
      padding-bottom: 0;
    }
  }

  .image-wrapper {
    @media screen and (max-width: $bpSmall) {
      margin-right: 0;
      align-self: flex-start;
    }
  }
}

.text-first {
  @media screen and (max-width: $bpSmall) {
    padding: 0 0 0 44px;
  }

  .text-wrapper {
    margin-left: 8%;
    margin-right: 30px;

    @media screen and (max-width: 1280px) {
      margin-left: 30px;
    }

    @media screen and (max-width: 960px) {
      padding-bottom: 20px;
    }

    @media screen and (max-width: $bpSmall) {
      margin-left: 0;
    }

    @media screen and (max-width: 585px) {
      margin-right: 0;
      padding-bottom: 0;
      width: 266px;
    }
  }

  a {
    @media screen and (max-width: 585px) {
      align-self: flex-end;
    }
  }

  .image-wrapper {
    @media screen and (max-width: $bpSmall) {
      margin-left: 0;
      align-self: flex-end;
    }
  }
}

.text-wrapper {
  max-width: 363px;
  width: 42%;

  @media screen and (max-width: 640px) {
    min-width: initial;
  }

  h3 {
    margin-bottom: 20px;
    text-transform: lowercase;

    @media screen and (max-width: $bpSmall) {
      margin-top: 20px;
    }
  }

  .sector {
    font-size: 16px;
    margin-bottom: 45px;

    @media screen and (max-width: $bpSmall) {
      margin-bottom: 30px;
    }
  }

  .description {
    margin-bottom: 65px;

    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .read-more {
    color: $midGrey;
    font-family: $medium;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    margin-top: 92px;
    transition: opacity 0.3s;
    position: relative;
    width: 200px;
    z-index: 5;

    @media screen and (max-width: 690px) {
      font-size: 20px;
      margin-top: 32px;
    }

    svg {
      fill: $midGrey;
      height: 20px;
      width: 58px;
      margin-left: 20px;
      transition: transform 0.5s;
    }

    &:hover {
      opacity: 0.5;

      svg {
        transform: translateX(8px);
      }
    }
  }
}

.image-wrapper {
  position: relative;
  padding-bottom: 13px;
  max-width: 894px;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $bpXLarge) {
    align-self: center;
  }

  @media screen and (max-width: $bpSmall) {
    width: initial;
  }

  &::after {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(107, 114, 148, 0.72) 0%,
      rgba(57, 62, 91, 0.72) 100%
    );
    height: calc(100% - 26px);
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    &::after {
      opacity: 0.8;
    }

    img {
      transform: scale(1.05);
    }
  }

  .bg-color {
    height: 13px;
    width: 100%;

    &.pink {
      background: $salmonGrad;
    }

    &.blue {
      background: $darkBlueGrad;
    }
  }

  .image-container {
    max-height: 540px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 680px) {
      height: 332px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.5s;
    }
  }
}

/* home page */
.featured {
  justify-content: flex-end;
  padding-top: 8px;

  .image-wrapper {
    @media screen and (max-width: $bpMedium) {
      margin: 0;
    }

    @media screen and (max-width: 690px) {
      width: initial;
    }
  }
}

.project-featured {
  composes: image-first;

  @media screen and (max-width: 1300px) {
    margin-top: 60px;
    padding-right: 20px;
  }

  @media screen and (max-width: 690px) {
    justify-content: flex-end;
    margin-top: 116px;
  }

  @media screen and (max-width: 675px) {
    margin-top: 240px;
    padding-left: 0;
    padding-right: 44px;
  }

  .text-wrapper {
    margin-left: 70px;
    margin-right: 0;

    @media screen and (max-width: $bpLarge) {
      margin-left: 40px;
    }

    @media screen and (max-width: $bpMedium) {
      width: 266px;
    }

    @media screen and (max-width: 585px) {
      width: 216px;
    }
  }

  .read-more {
    width: 230px;
  }
}

.news-featured {
  composes: text-first;
}

// featured project range-page
.range-page {
  composes: text-first;
  margin-top: 45px;
  padding-right: 0;
  padding-left: 45px;

  @media screen and (min-width: 585px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: $bpXLarge) {
    margin-top: 85px;
  }

  .sector {
    display: none;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }

  .text-wrapper {
    align-self: flex-start;
    margin-left: 0;

    @media screen and (min-width: $bpLarge) {
      margin-right: 20px;
    }
  }
}
