@import 'src/constants/index.scss';
@import 'components/filters/title-style.module.scss';

.filter-option {
  min-width: 140px;

  @media screen and (max-width: $bpXXLarge) {
    min-width: 115px;
  }

  @media screen and (max-width: 850px) {
    min-width: 95px;
  }

  @media screen and (max-width: $bpSmall) {
    background: $white;
    border: 1px solid $offWhite;
    padding: 0 22px 0 33px;
    margin: 0;
    width: 100%;
  }

  h3 {
    color: $darkGrey;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding-bottom: 10px;

    @media screen and (max-width: $bpSmall) {
      padding-bottom: 0;
    }
  }

  ul {
    margin-bottom: 10px;

    @media screen and (max-width: $bpSmall) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      color: $darkBlue;
      display: flex;
      align-items: center;
      height: 23px;

      label[for='90-99mm'] {
        @media screen and (max-width: 425px) {
          width: 82px;
        }

        @media screen and (max-width: 331px) {
          padding-left: 0;
          width: initial;
        }
      }

      label[for='100mm or more'] {
        @media screen and (max-width: 506px) {
          width: 112px;
        }

        @media screen and (max-width: 337px) {
          width: 114px;
          margin: 0;
        }
      }

      @media screen and (max-width: $bpSmall) {
        margin-top: 10px;
        min-width: 108px;
      }
    }
  }

  label {
    cursor: pointer;
    font-family: $regular;
    font-size: 12px;
    line-height: 24px;
    margin-left: 5px;

    &.is-checked {
      span {
        color: $darkGrey;
        font-family: $medium;
      }
    }
  }
}

label.is-disabled {
  text-decoration: line-through;
  cursor: default;
}
