@import 'src/constants/index.scss';

.spec-code {
  display: none;
  max-width: 1214px;
  margin: 0 auto;
  padding: 70px 20px 0 20px;

  @media screen and (min-width: $bpLarge) {
    display: block;
  }

  @media screen and (min-width: 1230px) {
    padding: 100px 0 0;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 21px;
    max-width: 480px;
  }
}

.spec-code-column {
  p {
    width: 100%;
    margin-bottom: 10px;
    &:not(.og-header) {
      text-align: left;
      display: inline !important;
      margin-top: 20px;
      padding: 4px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #555762;
      border-radius: 10px 10px 0 4px;
      background: #f4f4f4;

      /*
        text-align: left;
        display: inline-block !important;
        border: 2px solid white;
        margin-top: 29px;
        padding: 4px 10px;
        font-size: 14px;
        font-weight: bold;
        color: #555762;
        border-radius: 10px 10px 0 4px;
        background: #f4f4f4;
        width: 100%;
        background: linear-gradient(225deg,#6a6d84 0%,#6a6d84 100%);
        color: white;
    */
    }
  }
  .og-header {
    font-size: 16px;
    font-weight: normal;
    color: black;
  }
  .container {
    margin-top: 40px;
  }
  /*
  > div:first-of-type {
    > div {
      p:not(.og-header) {
        display: inline !important;
        margin-bottom: 0;
      }
      p.og-header{
        padding-left: 0;
      }
    }
  } */
}

.group-headings {
  margin-top: 30px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.code-container {
  background: $darkBlue;
  padding: 10px 16px;
  margin-bottom: 11px;
  color: $white;
  font-family: $medium;
  font-size: 25px;
  line-height: 50px;
  text-transform: uppercase;
  display: flex;

  @media screen and (min-width: $bpXLarge) {
    font-size: 35px;
  }

  span {
    text-align: center;

    &:nth-of-type(3) {
      width: 82px;
    }

    &:nth-of-type(13) {
      padding-left: 5px;
    }

    &:nth-of-type(5),
    &:nth-of-type(6),
    &:nth-of-type(7) {
      flex: 1;
      max-width: 9.7vw;

      @media screen and (min-width: $bpXLarge) {
        max-width: 12.5vw;
      }

      @media screen and (min-width: $bpXXLarge) {
        max-width: 165px;
      }
    }

    &:nth-of-type(9),
    &:nth-of-type(11) {
      width: 142px;

      @media screen and (min-width: $bpXXLarge) {
        width: 157px;
      }
    }
    width: auto !important;
    flex: 1;
  }

  .code {
    &:first-of-type {
      width: 62px;
      margin-right: 9px;
    }
  }

  .dash {
    opacity: 0.5;
    font-size: 30px;
  }

  .smaller {
    font-size: 25px;
  }

  .over-seven {
    font-size: 28px;
  }

  .over-nine {
    font-size: 22px;
  }
}

.btn {
  cursor: default;
  background: $offWhite;
  color: white;
  display: block;
  text-align: center;
  margin: 114px auto 0 auto;
  padding: 15px 43px;
  border-radius: 32px;
  font-size: 20px;
  pointer-events: none;
}

.ready {
  cursor: pointer;
  background: $darkBlue;
  pointer-events: initial;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.reverse {
  justify-content: flex-end;
  flex-direction: row;
}

.copy-btn {
  color: $darkBlue;
  font-family: $medium;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  text-align: right;
  padding-right: 0;
  margin-bottom: 10px;
  margin-left: 20px;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
  pointer-events: initial;

  &:hover {
    opacity: 0.5;
  }
}
