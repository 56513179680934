@import 'src/constants/index.scss';

.pagination {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  position: relative;
  width: 100%;

  @media screen and (min-width: $bpMedium) {
    margin: 60px 0;
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: center;

    @media screen and (max-width: $bpSmall) {
      width: 100%;
      flex: none;
      padding-bottom: 15px;
    }
  }

  button {
    cursor: pointer;
    transition: opacity 0.3s;
    color: $darkGrey;
    font-size: 18px;
    display: flex;

    &.current,
    &:hover {
      opacity: 0.5;
    }

    @media screen and (max-width: $bpSmall) {
      flex: 1;
    }
  }

  svg {
    height: 20px;
    width: 58px;
    display: block;
  }
}

.hidden {
  visibility: hidden;
}

.previous {
  @media screen and (max-width: $bpSmall) {
    order: 2;
  }

  svg {
    transform: rotate(180deg);
  }
}

.next {
  text-align: right;
  justify-content: flex-end;

  @media screen and (max-width: $bpSmall) {
    order: 3;
  }
}
