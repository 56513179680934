@import 'src/constants/index.scss';

.portrait {
  position: relative;
  margin-top: 15px;
  width: 79%;

  @media screen and (min-width: $bpSmall) {
    width: 50%;
  }

  @media screen and (min-width: $bpMedium) {
    margin-bottom: 0;
    width: 35%;
  }

  &::after {
    content: '';
    background: $lightDarkBlueGrad;
    height: 95%;
    width: 90%;
    position: absolute;
    right: 0;
    bottom: -34px;
    transform: rotate(-180deg);

    @media screen and (min-width: $bpSmall) {
      bottom: -54px;
      right: -49px;
      width: 100%;
    }
  }

  img {
    position: relative;
    z-index: 1;
  }

  span {
    color: $white;
    font-size: 42px;
    line-height: 42px;
    position: absolute;
    left: 24px;
    top: 50px;
    max-width: 352px;
    z-index: 2;
  }

  // callout component
  div {
    margin: 0 auto;
    z-index: 1;
  }
}

.hide-shadow {
  &::after {
    display: none;
  }
}
