@import 'src/constants/index.scss';

.get-in-touch-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 30px 55px 33px;
  position: relative;
  width: 100%;
  flex: 1;

  @media screen and (min-width: $bpSmall) {
    padding-top: 48px;
    padding-bottom: 78px;
  }

  @media screen and (min-width: $bpMedium) {
    padding: 105px 30px 0 6.5vw;
    display: block;
  }

  @media screen and (min-width: $bpXXLarge) {
    padding-left: 8.5vw;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }

    .go-btn {
      button {
        @media screen and (max-width: $bpMedium) {
          width: 238px;
        }
      }
    }
  }

  h2 {
    max-width: 360px;
    display: none;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }

  div {
    height: 82px;
    line-height: 18px;
  }

  &.fullform {
    @media screen and (min-width: $bpMedium) {
      padding-top: 16px;
    }

    div {
      height: 50px;

      &.contact,
      &.newsletter {
        display: flex;
        padding-bottom: 23px;
        height: initial;

        &.error {
          margin-top: 7px;
        }

        label {
          margin-top: -7px;

          div {
            height: initial;

            svg {
              stroke: $grey;
            }
          }
        }

        &.checked {
          small {
            color: $darkGrey;
          }

          label {
            div {
              svg {
                stroke: $blue;
              }
            }
          }
        }

        small {
          padding-left: 10px;
          max-width: 459px;

          @media screen and (max-width: 375px) {
            padding-right: 11px;
          }
        }
      }

      &.newsletter {
        margin-top: 16px;
      }

      button {
        margin: 0 auto 30px auto;

        @media screen and (min-width: $bpMedium) {
          margin: 0 0 30px 0;
        }
      }

      &:last-of-type {
        small {
          text-align: center;

          @media screen and (min-width: $bpMedium) {
            text-align: left;
          }
        }
      }
    }
  }

  /*  this removes the default behavior from the autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    @media screen and (min-width: $bpMedium) {
      -webkit-box-shadow: 0 0 0px 1000px $offWhite inset;
    }
  }

  input {
    display: block;
    width: 362px;

    &.email {
      @media screen and (min-width: $bpMedium) {
        width: 245px;
      }

      &.fullform {
        width: 362px;
      }
    }

    @media screen and (max-width: 375px) {
      width: 90vw;

      &.email {
        width: 66vw;
        &.fullform {
          width: 90vw;
        }
      }
    }
  }

  .name {
    opacity: 0;
    animation: slide-left 0.3s ease-in 0.2s forwards;
  }

  .surname {
    animation: slide-left 0.3s ease-in forwards;
  }

  small {
    font-size: 13px;
    line-height: 16px;
    max-width: 395px;
    display: inline-block;

    a {
      text-decoration: underline;
    }

    @media screen and (max-width: 375px) {
      max-width: 280px;
    }

    @media screen and (max-width: 360px) {
      max-width: 260px;
    }
  }
}
