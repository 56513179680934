@import 'src/constants/index.scss';

.sign-up-form {
  padding-top: 10px;
  width: 100%;

  input {
    width: 362px;

    &.email {
      width: 245px;

      &.fullform {
        width: 362px;
      }
    }

    @media screen and (max-width: 375px) {
      width: 90vw;

      &.email {
        width: 66vw;
        &.fullform {
          width: 90vw;
        }
      }
    }
  }

  div {
    min-height: 60px;
    line-height: 18px;
  }

  .name {
    opacity: 0;
    animation: slide-left 0.3s ease-in 0.2s forwards;
  }

  .surname {
    animation: slide-left 0.3s ease-in forwards;
  }

  small {
    &.terms {
      display: inline-block;
      padding-left: 10px;
      max-width: 340px;

      @media screen and (max-width: 375px) {
        padding-right: 11px;
      }
    }

    &.mandatory {
      padding-left: 23px;
      margin-top: 2px;
    }

    @media screen and (max-width: 375px) {
      max-width: 280px;
    }

    @media screen and (max-width: 360px) {
      max-width: 260px;
    }
  }

  .privacy {
    display: flex;
    align-items: flex-start;
    padding-bottom: 23px;
    position: relative;
    height: auto;

    &.error {
      margin-top: 7px;
    }

    label {
      margin-right: -9px;
      margin-top: -7px;
    }
  }

  .go-btn {
    position: absolute;
    bottom: 20px;
    right: 30px;

    @media screen and (max-width: 375px) {
      left: 68vw;
    }
  }
}

.confirmation {
  max-width: 400px;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  animation: slide-up 0.3s ease-in forwards;

  @media screen and (max-width: $bpXXLarge) {
    width: 40%;
    margin-left: 30px;
  }

  @media screen and (max-width: $bpMedium) {
    width: 50%;
  }

  @media screen and (max-width: $bpSmall) {
    margin: 0 auto;
    width: 75%;
  }
}
