@import 'src/constants/index.scss';

.collaborators {
  padding-bottom: 0;

  .title {
    padding-left: 75px;
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
      padding-left: 44px;
    }

    @media screen and (max-width: $bpSmall) {
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-bottom: 30px;
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1049px;

    @media screen and (max-width: $bpXLarge) {
      justify-content: center;
    }

    @media screen and (max-width: $bpSmall) {
      width: 90%;
    }

    @media screen and (max-width: 704px) {
      justify-content: center;
      width: 100%;
    }

    .images-wrapper {
      max-width: 134px;
      max-height: 134px;
      margin: 0 24px 20px 24px;

      @media screen and (max-width: $bpSmall) {
        margin: 0 21px 20px 21px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:nth-child(6) {
        margin-right: 0;
      }

      &:nth-child(7) {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:nth-child(6),
      &:last-of-type {
        @media screen and (max-width: $bpXLarge) {
          margin-right: 24px;
        }

        @media screen and (max-width: $bpSmall) {
          margin-right: 21px;
        }
      }

      &:first-of-type,
      &:nth-child(7) {
        @media screen and (max-width: $bpXLarge) {
          margin-left: 24px;
        }

        @media screen and (max-width: $bpSmall) {
          margin-left: 21px;
        }
      }

      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:last-of-type {
        @media screen and (max-width: $bpSmall) {
          display: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
