@import 'src/constants/index.scss';

.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 200;
  transition: transform 0.3s;
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $white;
  }
}

.notification-on {
  transition: transform 0.04s;

  @media screen and (min-width: $bpSmall) {
    transform: translate(0, 62px);
  }
}

.header-wrapper {
  background-color: rgba(255, 255, 255, 0);
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 0;
  transition: color 0.3s, background-color 0.3s;
  z-index: 200;

  .wrapper {
    padding: 16px 20px 17px 20px;
    // 1480px instead 1440px because of the side padding
    max-width: 1480px;

    @media screen and (max-width: $bpXLarge) {
      padding: 22px 17px 22px 17px;
      justify-content: center;
    }

    // mobile landscape
    @media screen and (min-width: 568px) and (max-height: $bpSmall) and (orientation: landscape) {
      padding: 17px 20px 17px 20px;
    }

    a {
      &.logo {
        position: relative;
        height: 42px;
        width: 176px;
      }
    }
  }

  img {
    height: 42px;
    width: 176px;
    position: absolute;
    top: 0;
    left: 0;

    // mobile landscape
    @media screen and (min-width: 568px) and (max-height: $bpSmall) and (orientation: landscape) {
      height: 37px;
      width: 158px;
    }

    @media screen and (min-width: $bpXLarge) {
      width: 166px;
    }
  }
}

.relative {
  position: relative;
}

.dark {
  color: $white;

  .black-logo {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.gradient {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(106, 109, 132, 0.28) 69%
  );
}

.light {
  color: $darkGrey;

  .black-logo {
    opacity: 1;
  }
}

.fixed,
.back-to-fixed {
  position: fixed;
  background: $white;
  color: $darkGrey;

  .black-logo {
    opacity: 1;
  }
}

.solid {
  background: $white;
  color: $darkGrey;

  .black-logo {
    opacity: 1;
  }
}
