@import 'src/constants/index.scss';

.footer-menu {
  margin: 61px 20px 0 0;
  min-width: 100px;

  &:nth-child(2) {
    @media screen and (max-width: 870px) {
      margin-right: 46px;
    }

    @media screen and (max-width: 720px) {
      margin-right: 0;
    }
  }

  &:nth-child(3) {
    @media screen and (max-width: 512px) {
      width: 36%;
    }
  }

  ul {
    line-height: 20px;

    a {
      font-size: 14px;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
