@import 'src/constants/index.scss';

.tooltip {
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 19px;

  div {
    background-color: $white;
    box-shadow: 0 2px 20px 0 rgba(54, 54, 54, 0.33);
    padding: 20px 45px 0 26px;
    position: absolute;
    top: 40px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;
    border-radius: 4px;
    display: none;

    &::before {
      content: '';
      background-color: #ffffff;
      height: 15px;
      width: 15px;
      position: absolute;
      top: -7px;
      right: 50%;
      transform: translateX(50%) rotate(45deg);
    }

    h3 {
      margin-bottom: 16px;
    }

    ul {
      margin-bottom: 20px;
      width: 134px;
    }

    h4,
    li {
      font-size: 13px;
      line-height: 16px;
    }

    h3,
    h4 {
      font-weight: 900;
    }

    h3,
    h4,
    li {
      color: $darkBlue;
    }
  }

  &:hover {
    div {
      display: block;
    }
  }
}
