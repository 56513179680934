@import 'src/constants/index.scss';

.social {
  &.footer-social {
    margin-top: 61px;

    @media screen and (max-width: $bpLarge) {
      order: 1;
    }

    @media screen and (max-width: 840px) {
      order: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .socials {
      display: flex;
      flex-wrap: wrap;
      margin-top: -12px;
      max-width: 145px;

      li {
        margin: 12px 6px 0 7px;

        &:first-of-type,
        &:nth-child(4) {
          margin-left: 0;
        }

        svg {
          stroke: $darkGrey;
          fill: $darkGrey;
          border-radius: 50%;
          transition: opacity 0.3s;
          height: 34px;
          width: 34px;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.mobile-social {
    margin-top: 150px;

    @media screen and (min-width: 320px) and (max-height: 736px) {
      margin-top: 100px;
    }

    @media screen and (min-width: 1024px) and (max-height: 769px) {
      margin-top: 100px;
    }

    @media screen and (min-width: 320px) and (max-height: 668px) {
      margin-top: 50px;
    }

    .socials {
      display: flex;
      justify-content: flex-end;
      margin-right: 100px;

      @media screen and (max-width: $bpSmall) {
        margin-right: 0;
        justify-content: space-between;
      }

      li {
        margin: 0 16px;

        &:last-of-type {
          margin-right: 0;
        }

        @media screen and (max-width: $bpSmall) {
          margin-left: 0;
        }

        svg {
          height: 35px;
          width: 35px;
          fill: $white;
          stroke: $white;

          @media screen and (min-width: 320px) and (max-height: 568px) {
            height: 26px;
            width: 26px;
          }
        }
      }
    }
  }
}
