.back-to-top {
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: fixed;
  top: 55vh;
  right: 3vw;
  z-index: 100;
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
  will-change: opacity;

  @media screen and (min-width: 1340px) {
    right: 6vw;
  }

  &.scroll {
    display: block;
    opacity: 1;
  }
}
