@import 'src/constants/index.scss';

.select {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 5px;

  &:nth-child(even) {
    @media screen and (min-width: $bpMedium) {
      width: 200px;
      margin-right: 5px;
    }
  }

  @media screen and (min-width: $bpMedium) {
    width: 200px;
    margin-right: 5px;
  }
}

.label {
  color: $midGrey;
  font-family: $regular;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
}
