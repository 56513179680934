@import 'src/constants/index.scss';
@import 'src/components/buttons/style.module.scss';

.work-with-us-form {
  position: relative;
  z-index: 5;
  width: 946px;

  @media screen and (max-width: $bpLarge) {
    padding-right: 25px;
    width: 100%;
  }

  small {
    display: block;
    margin-top: 8px;
    padding-left: 8px;

    &.error {
      color: $salmon;
    }
  }

  input {
    &::placeholder {
      color: $midGrey;
      font-size: 16px;
      opacity: 1;
    }
  }

  .top-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
    }

    .left-side {
      width: 466px;

      @media screen and (max-width: 980px) {
        padding-right: 50px;
        width: 60%;
      }

      @media screen and (max-width: $bpSmall) {
        padding-right: 0;
        width: 100%;
      }

      input {
        width: 100%;
      }
    }

    .name,
    .surname {
      input {
        margin-top: 50px;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      min-width: 405px;

      @media screen and (max-width: 980px) {
        min-width: 40%;
      }

      @media screen and (max-width: $bpSmall) {
        margin-top: 35px;
        order: 1;
      }

      .select-item-container {
        height: 50%;

        &:nth-child(2) {
          margin-top: 16px;
        }

        label {
          padding-left: 8px;
        }
      }
    }
  }

  .cv {
    display: flex;
    align-items: center;
    margin-top: 34px;

    label {
      cursor: pointer;
      border: none;
      background: $darkBlue;
      border-radius: 33px;
      color: white;
      font-size: 16px;
      font-family: $medium;
      line-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 265px;
    }

    input[type='file'] {
      display: none;
    }

    span {
      margin-left: 15px;
    }
  }

  .email-desktop {
    width: 100%;
  }

  .email {
    margin-top: 50px;
  }

  .textarea-wrapper {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 47px;
      padding-left: 10px;

      @media screen and (max-width: $bpSmall) {
        margin-top: 27px;
      }
    }
  }

  .privacy-wrapper {
    margin-top: 19px;
  }

  .privacy,
  .newsletter {
    display: flex;
    align-items: center;
    margin-top: -7px;

    small {
      margin-left: -10px;

      @media screen and (max-width: $bpSmall) {
        padding-left: 12px;
        font-size: 13px;
        line-height: 16px;
      }

      @media screen and (max-width: 640px) {
        margin: 12px 0;
        padding-left: 5px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 410px) {
        padding-top: 12px;
      }

      @media screen and (max-width: 368px) {
        &:last-of-type {
          padding-top: 22px;
        }
      }
    }
  }

  .privacy {
    margin-bottom: 5px;

    small {
      margin-top: -1px;
    }
  }

  .newsletter {
    small {
      margin-top: 3px;
    }
  }

  button {
    margin-bottom: 3px;
    margin-top: 20px;
  }

  .mandatory {
    padding-left: 22px;
  }

  .terms {
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
      color: $darkBlue;
    }
  }
}
