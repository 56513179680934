@import 'src/constants/index.scss';

.our-story {
  padding-top: 15px;

  .top-group,
  .bottom-group {
    display: flex;

    p {
      @media screen and (max-width: 740px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .top-group {
    justify-content: center;
    margin-bottom: 56px;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 820px) {
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    .image-and-description {
      margin-right: 35px;

      @media screen and (max-width: 1250px) {
        margin-right: 50px;
      }
    }

    .top-image {
      @media screen and (max-width: 980px) {
        object-fit: contain;
        margin: 0 auto;
      }
      @media screen and (max-width: 540px) {
        align-self: flex-start;
        height: 225px;
        margin: 0;
      }
    }

    .top-description {
      margin-top: 69px;
      width: 680px;

      @media screen and (max-width: 1250px) {
        margin-left: 30px;
      }
      @media screen and (max-width: $bpXLarge) {
        width: 623px;
      }
      @media screen and (max-width: 980px) {
        margin-top: -320px;
        order: 3;
        padding-right: 28px;
        width: 50%;
      }

      @media screen and (max-width: 820px) {
        margin: 65px auto 0 auto;
        width: 80%;
      }

      @media screen and (max-width: 540px) {
        padding-right: 0;
        width: 313px;
      }

      @media screen and (max-width: $bpXSmall) {
        width: 275px;
      }
    }

    .top-right-image {
      margin-left: 30px;

      @media screen and (max-width: $bpXLarge) {
        margin-left: 0;
      }

      @media screen and (max-width: 1250px) {
        margin-right: 15px;
      }

      @media screen and (max-width: 980px) {
        align-self: flex-end;
      }

      @media screen and (max-width: 820px) {
        margin-right: 10%;
      }

      @media screen and (max-width: 540px) {
        margin-right: 0;
      }

      img {
        margin-top: 312px;

        @media screen and (max-width: 980px) {
          margin-top: 50px;
          margin-left: 28px;
        }

        @media screen and (max-width: 540px) {
          height: 319px;
          width: 319px;
        }

        @media screen and (max-width: 360px) {
          margin-left: 0;
          height: 300px;
          width: 300px;
        }
      }
    }
  }

  .bottom-group {
    justify-content: flex-start;
    position: relative;

    @media screen and (max-width: 885px) {
      flex-direction: column;
    }

    .bg-color {
      background: linear-gradient(225deg, #d4afaa 0%, #ffffff 100%);
      position: absolute;
      left: 136px;
      top: 0;
      height: 807px;
      width: 478px;

      @media screen and (max-width: 1250px) {
        height: 607px;
        width: 378px;
      }

      @media screen and (max-width: $bpXLarge) {
        height: 557px;
        width: 318px;
        top: 50px;
      }

      @media screen and (max-width: 980px) {
        left: 100px;
      }

      @media screen and (max-width: 885px) {
        left: 50%;
        width: 255px;
        transform: translateX(-50%);
      }
    }

    .bottom-image {
      margin-top: 100px;
      margin-right: 59px;

      @media screen and (max-width: 1360px) {
        margin-right: 44px;
      }

      @media screen and (max-width: 540px) {
        margin-right: 0;
      }

      img {
        position: relative;
        z-index: 2;

        @media screen and (max-width: 1050px) {
          height: 433px;
          object-fit: cover;
        }

        @media screen and (max-width: 540px) {
          width: 100vw;
        }
      }
    }

    .bottom-description {
      width: 388px;

      @media screen and (max-width: 885px) {
        margin: 0 auto;
        width: 500px;
      }

      @media screen and (max-width: 540px) {
        width: 308px;
      }

      @media screen and (max-width: $bpXSmall) {
        width: 275px;
      }

      p {
        margin-top: 45px;

        @media screen and (max-width: $bpXLarge) {
          margin-top: 25px;
        }

        &:first-of-type {
          margin-top: 180px;
        }

        @media screen and (max-width: 1360px) {
          margin-right: 15px;
        }

        @media screen and (max-width: 1250px) {
          margin-top: 120px;
        }

        @media screen and (max-width: 540px) {
          margin-top: 75px;
          margin-right: 0;
        }
      }
    }
  }
}
