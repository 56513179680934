@import 'src/constants/index.scss';

.select-variant {
  padding: 0 33px;

  @media screen and (min-width: $bpSmall) {
    padding-top: 59px;
  }

  img {
    display: block;
    width: 100%;
  }

  h2 {
    font-size: 35px;
    line-height: 45px;
    margin: 0 auto 51px auto;
    max-width: 1024px;
    width: 100%;
  }

  .tick {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 19px;
    width: 19px;
    opacity: 0;

    @media screen and (min-width: $bpXLarge) {
      top: 19px;
      right: 19px;
      height: 27px;
      width: 27px;
    }
  }

  p {
    color: $white;
    font-size: 29px;
    line-height: 29px;
    font-weight: 500;
    position: absolute;
    bottom: 24px;
    left: 27px;

    @media screen and (min-width: $bpXLarge) {
      font-size: 42px;
      line-height: 42px;
      left: 39px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    cursor: pointer;
    position: relative;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.3s, transform 0.3s;
    will-change: opacity, transform;

    @media screen and (min-width: $bpSmall) {
      width: 32%;
      max-width: 330px;
      max-height: 330px;
    }

    &::before {
      content: '';
      background: $grey;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.6;
    }

    &::after {
      content: '';
      background: $salmonGrad;
      height: 13px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
    }

    &.active {
      opacity: 1;
      pointer-events: none;
      z-index: 1;

      @media screen and (min-width: $bpSmall) {
        transform: scale(1.1);
      }

      &::before {
        opacity: 0;
      }

      &::after,
      .tick {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
