@import 'src/constants/index.scss';

.intro {
  composes: intro from '../product-intro/style.module.scss';
  margin-top: 32px;
  padding: 0 0 0 34px;
  max-width: 1255px; //same as .family

  @media screen and (min-width: $bpSmall) {
    margin-top: 68px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 710px;

  h3 {
    margin-bottom: 20px;
    text-transform: lowercase;

    @media screen and (min-width: $bpSmall) {
      margin-bottom: 30px;
    }
  }

  p {
    max-width: 661px;
    margin-right: 4px;

    @media screen and (min-width: $bpSmall) {
      padding-left: 46px;
    }
  }
}
