.default {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.fit {
  height: 100%;
}
