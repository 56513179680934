@import 'src/constants/index.scss';

.intro {
  padding-left: 6.5%;
  padding-top: 65px;

  @media screen and (max-width: 1280px) {
    padding-left: 30px;
  }

  @media screen and (max-width: $bpMedium) {
    padding-top: 45px;
  }

  h3 {
    @media screen and (max-width: $bpXLarge) {
      font-size: 25px;
      line-height: 30px;
      margin: 21px 0;
    }
  }

  p {
    max-width: 970px;
    margin-right: 15px;
    white-space: pre-line;

    @media screen and (max-width: $bpXLarge) {
      max-width: 740px;
      margin: 0;
    }

    @media screen and (max-width: $bpMedium) {
      padding-right: 30px;
    }
  }
}
