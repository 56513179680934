@import 'src/constants/index.scss';

.title {
  width: 100%;
  position: relative;
  flex: 105px;

  @media screen and (min-width: $bpSmall) {
    padding-right: 40px;
  }

  @media screen and (min-width: $bpMedium) {
    flex: 1;
    max-width: 50%;
  }

  h2 {
    position: absolute;
    top: 24px;
    left: 34px;
    max-width: 200px;

    @media screen and (min-width: $bpSmall) {
      max-width: 318px;
      top: -37px;
    }

    @media screen and (min-width: $bpMedium) {
      left: 13vw;
    }

    @media screen and (min-width: $bpXLarge) {
      left: 17vw;
    }

    @media screen and (min-width: $bpXXLarge) {
      left: 18.8vw;
    }
  }
}

.rectangle {
  background: $roseGrad;
  position: absolute;
  top: 0;
  left: 0;
  height: 71px;
  width: 100%;
  max-width: 184px;

  @media screen and (min-width: $bpSmall) {
    top: -87px;
    height: 133px;
    max-width: 27vw;
  }

  @media screen and (min-width: $bpXXLarge) {
    max-width: 29.4vw;
  }
}
