@import 'src/constants/index.scss';

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 53px;
  margin-bottom: 20px;
  flex-direction: column;

  @media screen and (min-width: $bpSmall) {
    flex-direction: row;
    padding: 0 15px;
  }

  @media screen and (min-width: $bpLarge) {
  }
}

.select {
  display: flex;
  align-items: center;

  span {
    color: $darkGrey;
    font-family: $medium;
    font-size: 18px;
    margin-left: 15px;
  }

  label {
    line-height: 16px;
    display: flex;
  }
}

.amount {
  color: $darkBlue;
  margin-left: 15px;

  @media screen and (min-width: $bpSmall) {
    margin-left: 47px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media screen and (min-width: $bpSmall) {
    margin-top: 0;
    margin-right: 10px;
  }

  button {
    background: transparent;
    border: none;
    color: $midGrey;
    font-family: $medium;
    font-size: 16px;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    @media screen and (min-width: $bpSmall) {
      font-size: 18px;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &:last-of-type {
      margin-left: 15px;
    }
  }

  svg {
    width: 27px;
    height: 27px;
    margin-left: 10px;
    display: none;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }
}

.fade {
  opacity: 0.3;
}

.results {
  margin-bottom: 15px;

  @media screen and (max-width: $bpSmall) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}
