@import 'src/constants/index.scss';

.project-hero {
  margin-top: 110px;
  padding-top: 0;

  @media screen and (max-width: $bpXLarge) {
    margin-top: 80px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 0;
  }

  .wrapper {
    display: flex;

    @media screen and (max-width: $bpLarge) {
      flex-direction: column;
      overflow: hidden;
    }

    .image-wrapper {
      max-height: 638px;
      position: relative;
      z-index: -1;

      @media screen and (max-width: $bpLarge) {
        max-width: 100%;
        overflow: hidden;
      }

      @media screen and (max-width: 910px) {
        margin: 0;
      }

      @media screen and (max-width: 700px) {
        height: 475px;
      }

      img {
        @media screen and (max-width: 1380px) {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .overlay {
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0.16) 0%,
        rgba(255, 255, 255, 0.02) 22%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;

      @media screen and (max-width: $bpLarge) {
        display: none;
      }
    }

    .text-container {
      @media screen and (max-width: $bpLarge) {
        margin-top: -40px;
      }

      @media screen and (max-width: $bpSmall) {
        margin-top: -32px;
      }

      .text-wrapper {
        flex-direction: column;
        max-width: 638px;

        @media screen and (max-width: $bpLarge) {
          padding-left: 30px;
        }

        @media screen and (max-width: 700px) {
          padding-left: 25px;
        }

        h1 {
          text-transform: lowercase;
          margin-left: -140px;
          padding-top: 76px;

          @media screen and (max-width: 1517px) {
            font-size: 100px;
            line-height: 100px;
            margin-left: -39px;
          }

          @media screen and (max-width: 1280px) {
            font-size: 80px;
            line-height: 80px;
          }

          @media screen and (max-width: $bpLarge) {
            padding-top: 0;
            margin-left: 0;
            max-width: 550px;
          }

          @media screen and (max-width: 800px) {
            font-size: 60px;
            line-height: 65px;
            max-width: 320px;
          }

          @media screen and (max-width: $bpXSmall) {
            font-size: 55px;
          }
        }

        p {
          color: $white;
          font-family: $medium;
          font-size: 20px;
          line-height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          margin: 29px 120px 29px 0;
          text-transform: lowercase;
          float: right;
          height: 42px;
          width: 186px;

          @media screen and (max-width: 1530px) {
            margin-right: 17%;
          }

          @media screen and (max-width: 1380px) {
            margin-right: 35%;
          }

          @media screen and (max-width: 1200px) {
            margin-right: 25%;
          }

          @media screen and (max-width: $bpXLarge) {
            float: none;
            margin-right: 0;
            margin-left: 30%;
          }

          @media screen and (max-width: $bpLarge) {
            margin-left: 0;
          }

          @media screen and (max-width: 700px) {
            height: 36px;
            width: 143px;
          }

          &.pink {
            background: $salmonGrad;
          }

          &.blue {
            background: $darkBlueGrad;
          }
        }
      }
    }
  }
}
