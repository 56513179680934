@import 'src/constants/index.scss';

.featured-images {
  padding-bottom: 0;
  position: relative;

  @media screen and (max-width: 540px) {
    font-size: 16px;
    line-height: 22px;
  }

  h2 {
    width: 446px;
    opacity: 0.06;
    transform: rotate(-90deg);
    font-size: 220px;
    line-height: 140px;
    text-align: right;
    position: absolute;
    right: -5vw;
    top: -213px;

    @media screen and (max-width: $bpMedium) {
      display: none;
    }
  }

  .wrapper {
    max-width: 1363px;
    height: 689px;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 1195px) {
      height: auto;
    }

    @media screen and (max-width: $bpXLarge) {
      flex-direction: column-reverse;
      align-items: center;
    }

    @media screen and (max-width: 600px) {
      padding: 0;
    }

    .image-big {
      align-self: flex-end;
      height: 642px;
      width: 735px;
      margin-right: 21px;

      @media screen and (max-width: 1385px) {
        height: 542px;
        width: 635px;
      }

      @media screen and (max-width: 1195px) {
        height: 442px;
        width: 535px;
        align-self: center;
      }

      @media screen and (max-width: $bpXLarge) {
        margin-top: 50px;
        margin-right: 0;
        height: 642px;
        width: 735px;
      }

      @media screen and (max-width: $bpSmall) {
        width: 100vw;
      }
      @media screen and (max-width: 460px) {
        height: 328px;
        margin-top: 21px;
      }
    }

    .column-right {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 600px) {
        align-items: center;
      }

      @media screen and (max-width: 460px) {
        flex-direction: column-reverse;
      }

      .group {
        position: relative;

        .bg-color {
          height: 293px;
          width: 607px;
          position: absolute;
          top: 196px;
          left: 0;
          transform: rotate(180deg);

          &.pink {
            background: $salmonGrad;
          }

          &.blue {
            background: $darkBlueGrad;
          }

          @media screen and (max-width: 1320px) {
            height: 243px;
            width: 557px;
          }

          @media screen and (max-width: $bpXXLarge) {
            height: 193px;
            width: 507px;
          }

          @media screen and (max-width: $bpXLarge) {
            left: -34px;
            height: 293px;
            width: 607px;
          }

          @media screen and (max-width: 600px) {
            height: 181px;
            width: 400px;
            top: 121px;
          }
        }

        .image-small {
          height: 455px;
          width: 535px;
          margin-left: 34px;
          position: relative;

          @media screen and (max-width: 1320px) {
            height: 405px;
            width: 485px;
          }

          @media screen and (max-width: $bpXXLarge) {
            height: 355px;
            width: 435px;
          }

          @media screen and (max-width: $bpXLarge) {
            margin-left: 0;
            height: 455px;
            width: 535px;
          }

          @media screen and (max-width: 600px) {
            height: 281px;
            width: 331px;
          }
        }
      }

      .copy {
        margin-top: 83px;
        margin-left: 34px;
        width: 385px;

        @media screen and (max-width: $bpXLarge) {
          margin-left: 0;
        }

        @media screen and (max-width: 460px) {
          width: 321px;
          margin-bottom: 50px;
          margin-top: 0;
        }

        @media screen and (max-width: $bpXSmall) {
          width: 300px;
        }
      }
    }
  }
}
