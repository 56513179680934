@import 'src/constants/index.scss';

.main-image-container {
  position: relative;
  ul {
    position: absolute;
    bottom: 0;
    margin: 20px 10px;
    font-weight: bold;
    li {
      background-color: white;
      padding: 0 5px;
      &:first-child {
        margin-bottom: 7px;
      }
    }
  }
  img {
    height: 100%;
    object-fit: cover;
  }
}

.beam-table-container {
  padding-top: 20px;
  margin-bottom: 20px;
}

.beam-table {
  max-width: 100%;
  td {
    background-color: rgb(244, 244, 244);
    border-radius: 4px;
    padding: 3px 6px;
    line-height: 15px;
    height: 2em;
    font-size: 14px;
    color: rgb(85, 87, 98);
    text-align: center;
    min-width: 110px;
    &.header1 {
      font-weight: 600;
      background-color: rgb(208, 153, 105);
    }
    &.header2 {
      font-weight: 300;
      background-color: peachpuff;
    }
    &.selected-beam-angle {
      background-color: #f3ded6;
      /* border: 3px solid; */
      border-bottom: 0;
      border-top: 0;
      /* border-right: 5px solid; */
      /* border-left: 5px solid; */
      border-radius: 0;
      position: relative;
      z-index: 200;
      font-weight: bold;
      font-size: 1em;
      margin-left: 12px;
      p {
        position: relative;
        z-index: 5;
      }
      &::before {
        content: '';
        width: 120%;
        height: 168%;
        border-left: 14px solid #f3ded6;
        border-right: 14px solid #f3ded6;
        position: absolute;
        z-index: 1;
        background-color: #f3ded6;
        left: -10%;
        top: -10px;
        /* padding: 40px; */
        overflow: visible;
      }
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: #f3ded6;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
      &.first-row {
        &::before {
          border-top: 10px solid #f3ded6;
        }
      }
      &.last-row {
        &::before {
          border-bottom: 14px solid #f3ded6;
        }
      }
    }
    &.keys {
      background-color: white;
      text-align: left;
      padding-right: 15px;
    }
  }
  tr {
    td {
    }
  }
}

.quicksheet {
  width: 1626px;
  height: 1150px;
  padding: 30px;

  .intro-text {
    margin-bottom: 1em;
  }

  .top-details {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    max-height: 500px;
    & > div {
      width: 33.33333%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 500px;
      }
    }
    .main-image-container {
      position: relative;
      justify-content: flex-start;
      ul {
        position: absolute;
        bottom: 0;
        margin: 20px 10px;
        font-weight: bold;
        li {
          background-color: white;
          padding: 0 5px;
          &:first-child {
            margin-bottom: 7px;
          }
        }
      }
      img {
        max-height: 500px;
        text-align: left;
      }
    }
  }

  .lower-boxes {
    display: flex;
    align-items: flex-end;
    .manufacturer {
      width: 50%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      p {
        font-size: 0.8em;
      }
    }

    .circular {
      width: 50%;
      display: flex;
      justify-content: space-between;
      .bubbles {
        display: flex;
        justify-content: center;
        width: 33%;
        p {
          margin-top: 50px;
          border-radius: 44px;
          background-color: #dcdcdc;
          height: 63px;
          width: 221px;
          font-size: 33px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'LL Circular Medium Sub';
          padding-left: 15px;
          padding-right: 15px;
        }
        span {
          font-size: 16px;
          padding-left: 10px;
        }
      }
    }
  }

  .code-container-holder {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }

  .title-bar {
    display: flex;
    justify-content: space-between;
    background-color: $rose;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 16px 48px;
    z-index: 200;
    h1 {
      font-size: 35px;
      line-height: 50px;
    }
    h2 {
      font-size: 35px;
      line-height: 35px;
      img {
        height: 1em;
        padding-top: 9px;
      }
    }
  }
}
.quick-spec {
  height: 100vh;
  width: 100%;
  display: none;

  &.is-open {
    @media screen and (min-width: $bpLarge) {
      display: grid;
      place-content: center;
      background: rgba($white, 0.9);
      max-width: 100%;
      width: 100%;
      height: 100vh;
      padding-bottom: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 300;
    }

    .spec-bar {
      position: initial;
    }

    button {
      &:first-of-type {
        &::before {
          display: none;
        }
      }
    }
  }

  &.close {
    display: none;
  }
}

.wrapper {
  overflow-y: auto;
}

.flex {
  display: flex;
}

.spec-bar {
  display: none;
  background-color: $rose;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 16px 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;

  @media screen and (min-width: $bpLarge) {
    display: block;
  }

  div {
    align-items: center;
    justify-content: space-between;

    button {
      cursor: pointer;
      display: block;
      position: relative;
      margin-left: 34px;
      padding: 5px;
      height: 22px;
      width: 22px;

      @media screen and (min-width: $bpXLarge) {
        height: 28px;
        width: 28px;
      }

      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background: $darkGrey;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &::before {
        transform: translateX(-50%) rotate(-45deg);
      }

      &::after {
        transform: translateX(-50%) rotate(45deg);
      }

      &:hover {
        opacity: 0.5;
      }

      &:first-of-type {
        &::before {
          transform: translateX(-50%) rotate(-90deg);
        }

        &::after {
          left: 0;
          transform: translateX(0) rotate(0deg);
        }
      }
    }
  }
}

.minimized {
  .code-container {
    align-items: flex-start;
    max-width: 903px;
    padding: 0;

    div {
      width: initial;
    }
  }

  h3 {
    font-size: 21px;
    line-height: 24px;

    @media screen and (min-width: $bpXXLarge) {
      font-size: 30px;
      line-height: 45px;
    }
  }

  p,
  span {
    font-size: 13px;
    line-height: 19px;

    @media screen and (min-width: $bpXXLarge) {
      font-size: 16px;
    }
  }

  .bar-span {
    display: none;

    @media screen and (min-width: $bpXXLarge) {
      display: block;
    }
  }

  .code-container {
    div {
      &:last-of-type {
        min-width: unset;
      }
    }
  }

  .code {
    span {
      padding-left: 26px;
      padding-right: 0;
      margin: 0 8px;
    }
  }
}

.info-wrapper {
  background-color: $white;
  max-width: 1440px;
  margin: 0 auto;
  border: 1px solid #dfdfdf;
}

.header {
  color: $midGrey;
}

.code-container {
  display: flex;
  align-items: flex-start;
  padding: 29px;

  div {
    width: calc(100% / 9);
    padding: 0 10px;

    &:last-of-type {
      min-width: 200px;
    }

    li,
    p {
      color: $midGrey;
    }

    p,
    span,
    li {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.code {
  text-transform: uppercase;
  color: $darkGrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  width: 100%;

  span {
    display: block;
    font-size: 30px;
    padding-right: 35px;
  }
}

.info {
  align-items: center;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;

  div {
    // thumbnail
    &:first-of-type {
      max-width: 33%;

      @media screen and (min-width: $bpXXLarge) {
        max-width: 33%;
      }
    }

    // drawings
    &:nth-of-type(2) {
      max-width: 33%;

      figure {
        padding: 10px 15px;
      }
    }

    // spec summary
    &:last-of-type {
      border-left: 1px solid $grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* height: 406px; */
      max-width: 33%;

      @media screen and (min-width: $bpXXLarge) {
        max-width: 33%;
      }
    }
  }

  figure {
    width: 100%;
    height: 100%;
    max-height: 450px;
    max-width: 100%;

    img {
      display: block;
      height: 100%;
      max-height: 450px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.drawings {
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100%;
  max-height: inherit;
  overflow: hidden;

  a {
    display: block;
    cursor: pointer;
    position: relative;

    svg {
      fill: $darkBlue;
      stroke: $darkBlue;
      height: 57px;
      width: 57px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;
      will-change: opacity;
      opacity: 0;
      pointer-events: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($blue, 0);
      transition: background-color 0.3s;
      will-change: background-color;
    }

    &:hover {
      &::before {
        background-color: rgba($blue, 0.3);
      }

      svg {
        opacity: 1;
      }
    }
  }
}

.double-drawing {
  a {
    &:nth-of-type(1) {
      border-bottom: 1px solid $grey;
    }
  }

  figure {
    height: 203px;
    padding: 20px 14px 25px;
  }
}

.spec-summary {
  align-self: center;
  max-width: 430px;
  padding: 0 15px 0 49px;
  margin-top: 30px;
  margin-bottom: 30px;

  h3 {
    max-width: 330px;
    margin-bottom: 29px;
    line-height: 40px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: $midGrey;
  }

  address {
    color: $midGrey;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    margin-top: 29px;
    margin-bottom: 15px;
    max-width: 381px;
  }
}

.descriptions {
  display: block;
  /* max-height: 100px; */
  max-width: 100%;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background-color: inherit;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: inherit;

    @media screen and (min-width: $bpMedium) {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $midGrey;
  }
}

.code-summary {
  color: $darkGrey;
  text-transform: uppercase;
  margin-bottom: 20px;
}
