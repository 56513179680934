.html {
  strong {
    font-weight: bold;
  }

  ul {
    list-style: initial;
    padding-left: 40px;
    margin: 1em 0;
  }

  a {
    text-decoration: underline;
    transition: opacity 0.3s;
  }

  p {
    margin: 1em 0;
  }
}
