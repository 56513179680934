@import 'src/constants/index.scss';

.header-menu {
  width: 55%;

  @media screen and (max-width: $bpXLarge) {
    display: none;
  }

  ul {
    font-family: $regular;
    display: flex;
    justify-content: space-between;

    li {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;

      a {
        font-size: $a;
        line-height: $a;
        position: relative;
        display: block;
        padding: 7px 0;
        transition: color 0.2s;
      }

      &:after {
        content: '';
        color: inherit;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-style: solid;
        border-width: 1px;
        transition: opacity 0.5s;
        opacity: 0;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
