@import 'src/constants/index.scss';

.worldwide-offices {
  padding: 0 114px 0 112px;
  margin-top: -35px;
  margin-bottom: 100px;

  @media screen and (max-width: 1300px) {
    padding: 0 64px 0 62px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 44px 0 42px;
  }

  @media screen and (max-width: $bpXLarge) {
    padding: 0 33px 0 32px;
    margin-top: 0;
  }

  @media screen and (max-width: $bpMedium) {
    padding: 0 114px 0 112px;
  }

  @media screen and (max-width: 820px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 780px) {
    padding: 0 33px 0 32px;
  }

  @media screen and (max-width: 585px) {
    margin-bottom: 35px;
  }

  .offices {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 66px;

    @media screen and (max-width: $bpMedium) {
      flex-wrap: wrap;
      margin-top: 16px;
    }

    .office {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      width: 245px;
      height: 240px;

      @media screen and (max-width: 1300px) {
        width: 214px;
      }

      @media screen and (max-width: $bpXLarge) {
        width: 178px;
      }

      @media screen and (max-width: $bpMedium) {
        margin-top: 50px;
        width: 42%;
      }

      @media screen and (max-width: 585px) {
        justify-content: flex-start;
        width: 100%;
      }

      &:first-of-type {
        @media screen and (max-width: 585px) {
          height: 200px;
        }

        ul {
          @media screen and (max-width: 585px) {
            height: 90px;
          }
        }

        .open-in-maps {
          @media screen and (max-width: 585px) {
            margin-top: 0;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        @media screen and (max-width: 585px) {
          height: 220px;
        }

        ul {
          @media screen and (max-width: 585px) {
            height: 90px;
          }
        }
      }

      .timezone {
        color: $offWhite;
        font-family: $medium;
        font-size: 60px;
        line-height: 70px;
        position: absolute;
        top: -45px;
        z-index: -1;

        @media screen and (max-width: 1300px) {
          font-size: 50px;
        }

        @media screen and (max-width: $bpXLarge) {
          font-size: 40px;
          top: -40px;
        }

        @media screen and (max-width: $bpMedium) {
          font-size: 60px;
          top: -45px;
        }
      }

      h4 {
        border-bottom: 2px solid $darkGrey;
        margin-bottom: 37px;

        @media screen and (max-width: $bpSmall) {
          font-size: 20px;
          line-height: 32px;
        }

        @media screen and (max-width: 585px) {
          margin-bottom: 22px;
        }
      }

      ul {
        height: 110px;
      }

      li {
        font-size: 16px;
        line-height: 22px;
      }

      .open-in-maps {
        color: $midGrey;
        font-family: $medium;
        font-size: 20px;
        line-height: 32px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        position: relative;

        @media screen and (max-width: $bpXLarge) {
          font-size: 18px;
        }

        @media screen and (max-width: $bpMedium) {
          font-size: 20px;
        }
      }
    }

    .arrow {
      margin-left: 32px;
      height: 20px;
      width: 58px;

      @media screen and (max-width: $bpXLarge) {
        margin-left: 18px;
        height: 16px;
        width: 48px;
      }

      @media screen and (max-width: $bpMedium) {
        margin-left: 32px;
        height: 20px;
        width: 58px;
      }
    }
  }

  a {
    position: relative;

    &:after {
      content: '';
      color: inherit;
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 0;
      border-style: solid;
      border-width: 1px;
      transition: opacity 0.5s;
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
