@import 'src/constants/index.scss';

.family {
  margin-top: 180px;

  .wrapper {
    max-width: 1255px;
    margin: 0 auto;
    padding-top: 45px;
    position: relative;

    @media screen and (min-width: $bpMedium) {
      padding: 32px 0;
    }

    &::after {
      @media screen and (min-width: $bpSmall) {
        content: '';
        background: $grey;
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        max-width: 1191px;
        width: 100%;
      }
    }
  }

  img {
    margin-left: 10px;
    max-width: 29%;
    width: 100%;

    @media screen and (min-width: $bpSmall) {
      border: 1px solid $grey;
      margin-left: 0;
      transition: border-color 0.3s;
      will-change: border-color;
    }

    @media screen and (min-width: $bpMedium) {
      max-width: 100%;
    }
  }

  h2 {
    font-size: 28px;
    letter-spacing: 1.24px;
    line-height: 36px;
    margin-bottom: 40px;
    padding-left: 33px;

    @media screen and (min-width: $bpSmall) {
      margin: 28px 0;
    }

    @media screen and (min-width: $bpMedium) {
      margin-top: 38px;
      margin-bottom: 0;

      span {
        display: none;
      }
    }
  }

  h3,
  p {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 25px;
  }

  p {
    opacity: 0.5;
    line-height: 20px;
    transition: opacity 0.3s, color 0.3s;
    will-change: color, opacity;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: lowercase;

    @media screen and (min-width: $bpSmall) {
      padding: 0 10px 0 34px;
    }

    @media screen and (min-width: 1290px) {
      padding: 0;
      max-width: 1193px;
      margin: 0 auto;
    }
  }

  li {
    cursor: pointer;
    border-bottom: 1px solid $grey;
    overflow: hidden;
    padding: 18px 0 24px 0;
    position: relative;
    width: 100%;

    @media screen and (min-width: $bpSmall) {
      width: 48%;
      border-bottom: none;
    }

    @media screen and (min-width: $bpMedium) {
      padding: 0;
      max-width: 196px;
      margin: 44px 1px 0 1px;
    }

    @media screen and (min-width: $bpXLarge) {
      &:hover {
        img {
          border: 1px solid $darkBlue;
        }

        p {
          opacity: 1;
        }

        h3,
        p {
          color: $darkBlue;
        }
      }
    }

    div {
      margin-left: 17px;

      @media screen and (min-width: $bpMedium) {
        margin-left: 0;
      }
    }
  }

  svg {
    fill: $salmon;
    height: 22px;
    width: 31px;
    position: absolute;
    right: 20px;
    top: 18px;

    @media screen and (min-width: $bpXSmall) {
      width: 41px;
    }

    @media screen and (min-width: $bpSmall) {
      right: initial;
      left: 19%;
      width: 31px;
    }

    @media screen and (min-width: $bpMedium) {
      left: initial;
      right: 12px;
      top: 10px;
      width: 41px;
    }
  }

  a {
    display: flex;
    align-items: center;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }
}

.tooltip-holder {
  position: absolute;
  /* bottom: 0; */
  bottom: 102px;
  /* width: 100%; */
  right: 12px;
  padding: 0 12px;
  padding-right: 0;
  /* right: 0; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media screen and (max-width: 939px) {
    bottom: 0;
  }
}
