@import 'src/constants/index.scss';

.sub-filter {
  height: auto;
  width: 100%;

  @media screen and (max-width: $bpSmall) {
    background: rgba($offWhite, 0.4);
  }

  .filter-header {
    background: $offWhite;
    color: $darkGrey;
    font-size: 18px;
    height: 50px;

    span {
      cursor: pointer;
    }

    @media screen and (max-width: $bpSmall) {
      background: $white;
      font-size: 14px;
      padding: 0 22px 0 33px;
    }

    &.is-open {
      @media screen and (max-width: $bpSmall) {
        background: rgba($offWhite, 0.4);
      }
    }
  }

  .filter-options {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 100px 20px 100px;
    opacity: 1;

    @media screen and (max-width: 1220px) {
      padding: 0 64px;
    }

    @media screen and (max-width: 1023px) {
      padding: 0 5px;
    }

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
      padding: 0;
    }

    &.hide {
      display: none;
      opacity: 0;
    }
  }
}
