@import 'src/constants/index.scss';

.page-range {
  // Note: we need overflow: hidden because of the vertical copy on this page
  overflow: hidden;
  margin-top: 147px;

  @media screen and (min-width: $bpXLarge) {
    margin-top: 137px;
  }

  &.range-nav-on {
    margin-top: 147px;

    @media screen and (min-width: $bpLarge) {
      margin-top: calc(147px + 60px);
    }

    @media screen and (min-width: $bpXLarge) {
      margin-top: calc(136px + 60px);
    }
  }

  &.remove-margin-top {
    margin-top: 0;
  }
}
