@import 'src/constants/index.scss';

.project-info {
  padding-bottom: 0;

  @media screen and (max-width: $bpMedium) {
    padding-top: 0;
  }

  @media screen and (max-width: $bpSmall) {
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 122px;

    @media screen and (max-width: 1500px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 600px) {
      padding: 0;
    }

    .title-wrapper {
      margin-bottom: 18px;

      @media screen and (max-width: 600px) {
        padding: 0 28px;
      }
    }

    .content {
      display: flex;

      @media screen and (max-width: 1400px) {
        img {
          height: 100%;
          width: 100%;
        }
      }

      @media screen and (max-width: $bpMedium) {
        flex-direction: column;

        .secondary-image {
          align-self: center;
          order: 4;
          height: 315px;
          max-width: 375px;
          width: 100%;
          overflow: hidden;

          img {
            object-fit: cover;
          }
        }

        .project-details {
          display: flex;
          flex-direction: column;
          margin-bottom: 55px;
        }
      }

      @media screen and (max-width: 600px) {
        .project-details {
          padding: 0 28px;
        }
      }

      @media screen and (max-width: 500px) {
        .secondary-image {
          min-width: 100%;
        }
      }

      .column-left,
      .project-details,
      .column-right,
      .main-image {
        display: flex;
        flex-direction: column;
      }

      .column-left {
        margin-right: 20px;

        .project-details {
          margin-bottom: 80px;
        }

        .secondary-image {
          max-height: 656px;
          max-width: 333px;
          overflow: hidden;
        }
      }

      .column-right {
        .image-wrapper {
          max-height: 553px;
          max-width: 100%;
          width: 965px;
          overflow: hidden;

          @media screen and (max-width: 1400px) {
            width: initial;
          }

          .main-image {
            max-height: 553px;

            img {
              max-height: 540px;
              object-fit: cover;

              @media screen and (max-width: 600px) {
                min-height: 315px;
              }
            }

            .bg-color {
              height: 13px;
              width: 100%;

              &.blue {
                background: $darkBlueGrad;
              }

              &.pink {
                background: $salmonGrad;
              }
            }
          }
        }

        .description {
          margin-top: 45px;
          max-width: 850px;

          p {
            margin: 1rem 0;
          }

          @media screen and (max-width: $bpMedium) {
            margin: 60px auto 44px auto;
            max-width: 650px;
          }

          @media screen and (max-width: 600px) {
            max-width: 318px;
          }

          @media screen and (max-width: 520px) {
            margin: 0;
            padding: 60px 0 44px 28px;
            max-width: 346px;
          }

          @media screen and (max-width: 360px) {
            padding-right: 15px;
          }
        }
      }
    }
  }
}

.project-details {
  p {
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
}
