@import 'src/constants/index.scss';

.partners-and-clients {
  margin-top: 15px;
  position: relative;

  /* floating element
  */
  h2 {
    width: 823px;
    opacity: 0.06;
    transform: rotate(-90deg);
    font-size: 220px;
    line-height: 140px;
    text-align: right;
    position: absolute;
    top: 70px;
    left: -330px;

    @media screen and (max-width: 1350px) {
      font-size: 200px;
      top: 129px;
    }

    @media screen and (max-width: 680px) {
      top: 488px;
      left: initial;
      right: -29%;
    }

    @media screen and (max-width: 540px) {
      // right: -29%;
    }

    &.full-list {
      top: 443px;
    }
  }

  h3 {
    margin-bottom: 26px;
    padding-left: 386px;

    @media screen and (max-width: 1320px) {
      padding-left: 306px;
    }

    @media screen and (max-width: 935px) {
      padding-left: 0;
      display: flex;
      justify-content: center;
    }

    @media screen and (max-width: 680px) {
      margin-bottom: 54px;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1165px) {
      justify-content: flex-start;
      padding-left: 30px;
    }

    @media screen and (max-width: 1111px) {
      justify-content: center;
      padding: 30px;
    }

    @media screen and (max-width: 680px) {
      flex-direction: column;
      padding: 30px 0 0 30px;
    }

    @media screen and (max-width: 480px) {
      padding: 0;
    }

    @media screen and (max-width: $bpXSmall) {
      width: 320px;
      overflow: hidden;
    }

    .list-wrapper {
      max-width: 296px;
      min-width: 296px;

      @media screen and (max-width: $bpXLarge) {
        min-width: initial;
      }

      &:nth-child(2) {
        margin: 0 80px;

        @media screen and (max-width: 680px) {
          margin: 35px 0;
        }
      }

      h4 {
        padding-left: 10px;
        position: relative;

        @media screen and (max-width: 840px) {
          font-size: 18px;
        }

        @media screen and (max-width: 680px) {
          padding-left: 33px;
        }

        &::after {
          content: '';
          background: $darkGrey;
          height: 2px;
          width: 100%;
          position: absolute;
          bottom: -9px;
          left: 0;
        }
      }

      .list {
        font-size: 18px;
        line-height: 30px;
        padding-left: 10px;
        margin-top: 26px;
        height: 155px;
        overflow: hidden;

        @media screen and (max-width: 840px) {
          font-size: 16px;
        }

        @media screen and (max-width: 935px) {
          max-width: 195px;
        }

        @media screen and (max-width: 680px) {
          max-width: 296px;
        }

        @media screen and (max-width: 480px) {
          padding-left: 33px;
        }

        &.full-list {
          height: auto;
        }
      }
    }
  }

  button {
    margin: 0 auto;
    margin-top: 30px;

    @media screen and (max-width: 1350px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 680px) {
      display: none;
    }
  }
}
