@import 'src/constants/index.scss';

.absolute {
  position: absolute;
  pointer-events: none;
  background-repeat: no-repeat;
}

.small {
  height: 517px;
  width: 522px;
  background-size: 517px 522px;
}

.big {
  height: 677px;
  width: 684px;
  background-size: 677px 684px;
}

.projects {
  top: 1374px;
  opacity: 0.7;
  z-index: 1;
  right: 0;

  @media screen and (max-width: 1440px) {
    width: 621px;
  }

  @media screen and (max-width: $bpXLarge) {
    display: none;
  }
}

.collaborators {
  opacity: 0.7;
  position: absolute;
  left: -345px;
  top: -143px;

  @media screen and (max-width: $bpMedium) {
    height: 328px;
    width: 331px;
    background-size: 328px;
    left: -157px;
    top: -119px;
  }
}

/* home page */
.realise {
  right: 0;
  top: 600px;

  @media screen and (max-width: $bpXLarge) {
    top: -120px;
    width: 433px;
  }
}

.enhance {
  top: 450px;

  @media screen and (max-width: $bpXLarge) {
    top: 0;
    right: 0;
  }

  @media screen and (max-width: $bpLarge) {
    top: 20%;
    width: 433px;
  }
}

/* product detail page */
.product-intro {
  right: 0px;
  top: 400px;
  width: 100%;
  background-position: right;

  @media screen and (max-width: 1440px) {
    width: 557px;
    background-position: initial;
  }

  @media screen and (max-width: $bpXLarge) {
    top: -250px;
    z-index: -1;
  }
}

/* our story page */
.story-description {
  right: 0;
  top: 10%;
  height: 925px;
  width: 100%;
  background-size: 925px;
  background-position: right;

  @media screen and (max-width: 1440px) {
    width: 709px;
    background-position: initial;
  }

  @media screen and (max-width: $bpXLarge) {
    top: -75%;
    height: 359px;
    width: 272px;
    background-size: 363px;
  }
}

.beauty {
  bottom: -45px;

  @media screen and (max-width: 780px) {
    right: 0;
    bottom: 0;
    width: 221px;
    background-size: 421px;
  }
}

/* why orluna page */
.why-orluna {
  right: 0;
  bottom: -92px;
  height: 267px;
  width: 267px;
  background-size: 444px;
  z-index: 1;

  @media screen and (min-width: $bpMedium) {
    display: none;
  }
}

/* contact page */
.partners {
  top: 300px;
  right: 0px;
  opacity: 0.5;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    height: 632px;
    width: 439px;
  }

  @media screen and (max-width: $bpXLarge) {
    top: -177px;
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
}

/* work with us page */
.contact-form {
  top: -50px;
  right: 0px;
  opacity: 0.5;

  @media screen and (max-width: $bpXLarge) {
    height: 435px;
    width: 253px;
    background-size: 435px;
  }
}

/* faq page */
.faq {
  top: -257px;
  right: 0;
  width: 100%;
  background-position: right;
  z-index: -1;

  @media screen and (max-width: 1440px) {
    width: 560px;
    background-position: initial;
  }

  @media screen and (max-width: $bpSmall) {
    width: 257px;
  }
}

/* people page */
.callout {
  left: 95px;
  bottom: -190px;
  height: 665px;
  width: 671px;

  img {
    height: 665px;
    width: 671px;
  }

  @media screen and (max-width: $bpXLarge) {
    left: -30%;
  }

  @media screen and (max-width: $bpSmall) {
    left: -100%;
    z-index: 1;
  }
}

/* news listing page */
.news {
  right: 0;
  top: 700px;
  z-index: 1;

  @media screen and (max-width: $bpXLarge) {
    display: none;
  }
}

.carbolite-section {
  position: absolute;
  top: 0;
  left: -25%;
  height: 100%;
  width: 100%;
  background-position: center center;
  @media screen and (max-width: $bpMedium) {
    height: 517px;
    background-size: contain;
  }
}

.parallax-sun {
  height: 100%;
  width: 100%;
  background-position: center center;
  margin-top: 42%;
  @media screen and (min-width: 1320px) {
    margin-top: 24vw;
  }
  @media screen and (min-width: 1920px) {
    background-size: 26vw 26vw;
  }
}
