@import 'src/constants/index.scss';

.burger-wrapper {
  height: 23px;
  width: 23px;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;

  @media screen and (min-width: 1101px) {
    display: none;
  }

  .nav-burger {
    .bar {
      background-color: $white;
      position: relative;
      height: 2px;
      width: 16px;
      transition: background-color 0.3s;

      &:before {
        content: '';
        background: inherit;
        position: absolute;
        right: 0;
        bottom: 7px;
        height: inherit;
        width: 23px;
        transition: transform 0.3s;
      }

      &:after {
        content: '';
        background: inherit;
        position: absolute;
        right: 0;
        top: 7px;
        height: inherit;
        width: 8px;
        transition: transform 0.3s;
      }
    }

    &.dark {
      .bar {
        background-color: $white;
      }
    }

    &.light,
    &.solid {
      .bar {
        background-color: $darkGrey;
      }
    }

    &.is-open {
      .bar {
        &.animate {
          background-color: transparent;

          &::after {
            background-color: $white;
            width: 16px;
            transform: translateY(-7px) rotate(-45deg);
          }

          &::before {
            background-color: $white;
            width: 16px;
            transform: translateY(7px) rotate(45deg);
          }
        }
      }
    }
  }
}
