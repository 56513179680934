@import 'src/constants/index.scss';

.list {
  margin-top: 28px;
  z-index: 5;

  .item-wrapper {
    .item {
      border-bottom: 2px solid $darkBlue;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 23px;
      padding-bottom: 23px;

      .arrow-wrapper {
        transition: transform 0.3s ease-in;

        &.rotate-down {
          transform: rotate(-180deg);
        }
      }

      svg {
        height: 7px;
        min-width: 10px;
      }

      &.is-open {
        border-bottom: none;
      }
    }

    .item-info {
      display: none;
      flex-direction: column;
      padding-bottom: 36px;

      .address {
        margin: 12px 0;
      }

      &.is-open {
        animation: slide-down 0.15s ease-in forwards;
        border-bottom: 2px solid $darkBlue;
        display: flex;
        opacity: 0;
      }
    }
  }
}

.faqs {
  background: transparent;
  margin-top: -85px;
  max-width: 1190px;

  @media screen and (max-width: 1300px) {
    padding-left: 30px;
    padding-right: 25px;
  }

  @media screen and (max-width: 1250px) {
    margin-top: -109px;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 0;
    padding-top: 0;
  }

  @media screen and (max-width: 1050px) {
    margin-top: -30px;
  }

  @media screen and (max-width: $bpLarge) {
    padding-left: 30px;
  }

  @media screen and (max-width: 820px) {
    margin-top: 45px;
  }

  h3 {
    padding-left: 10px;
    margin-bottom: 60px;

    @media screen and (max-width: $bpMedium) {
      margin-bottom: 35px;
    }
  }

  .item-wrapper {
    &.faq {
      width: 100%;
    }

    .item {
      li {
        font-family: $medium;
        font-size: 20px;
        line-height: 32px;
        padding-left: 10px;
      }
    }

    .arrow-wrapper {
      margin-right: 27px;
      margin-left: 15px;

      @media screen and (max-width: 540px) {
        align-self: flex-end;
        margin-right: 4px;
      }
    }

    .item-info {
      padding-bottom: 30px;
      border-bottom: 2px solid $darkGrey;

      &.is-open {
        display: flex;
      }

      .answer {
        font-family: $regular;
        font-size: 20px;
        line-height: 32px;
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 1049px;
        padding-left: 10px;

        @media screen and (max-width: 540px) {
          font-size: 18px;
          line-height: 30px;
          max-width: 294px;
        }

        a {
          text-decoration: underline;
        }
      }

      button {
        margin-left: 10px;
      }
    }
  }
}

.partners {
  margin-top: 102px;
  padding: 0 110px;

  @media screen and (max-width: $bpLarge) {
    padding: 0 80px;
  }

  @media screen and (max-width: 840px) {
    margin-top: 55px;
    padding: 0 28px;
  }

  h3 {
    @media screen and (max-width: 722px) {
      margin-bottom: 35px;
      text-align: center;
    }

    @media screen and (max-width: 375px) {
      text-align: left;
    }
  }

  .columns-wrapper {
    display: flex;
    margin-bottom: 159px;

    @media screen and (max-width: $bpSmall) {
      margin-bottom: 60px;
    }

    @media screen and (max-width: 722px) {
      flex-direction: column;
      align-items: center;
    }

    .list {
      @media screen and (max-width: 722px) {
        margin: 0;
      }

      &:first-of-type {
        margin-right: 100px;

        @media screen and (max-width: $bpLarge) {
          margin-right: 50px;
        }

        @media screen and (max-width: 840px) {
          margin-right: 30px;
        }

        @media screen and (max-width: 722px) {
          margin: 0;
        }
      }
    }

    .partner-contact {
      &:hover {
        text-decoration: underline;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .partner-wrapper {
      margin-top: 20px;

      &:nth-child(2) {
        margin-top: 40px;
      }
    }
  }

  .item-wrapper {
    width: 371px;

    @media screen and (max-width: $bpLarge) {
      width: 345px;
    }

    @media screen and (max-width: $bpMedium) {
      width: 325px;
    }

    @media screen and (max-width: 840px) {
      width: 315px;
    }

    .item {
      li {
        padding-left: 18px;
      }
    }

    .arrow-wrapper {
      margin-right: 173px;

      @media screen and (max-width: $bpMedium) {
        margin-right: 30px;
      }
    }

    .item-info {
      padding-left: 39px;
      color: $black;
      font-size: 16px;
      line-height: 20px;

      .address {
        margin: 12px 0;
        display: block;
      }

      @media screen and (max-width: 840px) {
        padding-left: 25px;
      }

      @media screen and (max-width: 375px) {
        padding-left: 18px;
      }

      &:first-of-type {
        margin-top: 20px;
      }
    }
  }
}
