@import 'src/constants/index.scss';

.search {
  background-color: $offWhite;
  margin-top: 85px;
  width: 100%;

  // mobile landscape
  @media screen and (min-width: 568px) and (max-height: $bpSmall) and (orientation: landscape) {
    margin-top: 75px;
  }

  @media screen and (min-width: $bpLarge) {
    margin-top: 0;
  }

  &.range-nav-hidden {
    margin-top: 86px;

    @media screen and (min-width: $bpXLarge) {
      margin-top: 75px;
    }
  }

  .search-container {
    position: relative;
    max-width: 1480px;
    margin: 0 auto;

    &.mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 250;
    }
  }

  .button-container {
    background: $offWhite;
    position: absolute;
    right: 2px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    z-index: 2;

    div {
      display: flex;
    }
  }

  .search-view {
    background: $white;
    height: 55px;
    bottom: initial;
    top: 0;
    padding-right: 26px;
  }
}

.filter-toggle {
  color: $midGrey;
  font-family: $regular;
  font-size: 18px;
  font-weight: 300;
  padding-right: 17px;
  transition: opacity 0.3s;

  @media screen and (min-width: $bpMedium) {
    margin-left: 7px;
  }

  svg {
    height: 7px;
    width: 10px;
    margin-left: 9px;
    transition: transform 0.3s ease-in;

    @media screen and (min-width: $bpMedium) {
      margin-left: 19px;
    }
  }

  &:hover {
    @media screen and (min-width: $bpXLarge) {
      opacity: 0.5;
    }
  }
}

.close {
  display: flex;
  @media screen and (min-width: $bpXLarge) {
    width: 8vw;
  }

  img {
    cursor: pointer;
    width: 20px;
    margin-left: 48px;
    transition: opacity 0.3s;
    opacity: 0.7;

    &:hover {
      opacity: 0.3;
    }
  }
}

.is-open {
  svg {
    transform: rotate(-180deg);
  }
}
