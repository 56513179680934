@import 'src/constants/index.scss';

.section-with-portrait {
  display: flex;
  align-items: center;

  > div {
    width: 50%;
  }

  @media screen and (max-width: 779px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
