@import 'src/constants/index.scss';

.search-view {
  margin-top: 156px;
  height: calc(100vh - 156px);
  position: fixed;
  width: 100%;

  @media screen and (min-width: $bpLarge) {
    margin-top: 175px;
    height: calc(100vh - 175px);

    &.notification-on {
      height: calc(100vh - 175px - 62px);
    }
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;

    @media screen and (min-width: $bpLarge) {
      max-width: 148px;
    }
  }

  p {
    opacity: 0.5;
    font-size: 16px;
    line-height: 24px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (min-width: $bpSmall) {
      padding-bottom: 46px;
    }

    @media screen and (min-width: $bpMedium) {
      padding-left: 33px;
    }

    @media screen and (min-width: $bpXLarge) {
      padding-bottom: 86px;
      padding-left: 87px;
    }
  }

  li {
    cursor: pointer;
    border-bottom: 1px solid $grey;
    overflow: hidden;
    padding: 35px 10px 35px 15px;
    position: relative;
    transition: color 0.3s;
    will-change: color;
    width: 100%;

    &:hover {
      color: $salmon;
    }

    @media screen and (min-width: $bpSmall) {
      width: 30%;
      border-bottom: none;
    }

    @media screen and (min-width: $bpMedium) {
      padding: 0;
      max-width: 19%;
      margin: 44px 4px 0 8px;
    }

    @media screen and (min-width: $bpLarge) {
      max-width: 15%;
    }

    div {
      margin-left: 17px;

      @media screen and (min-width: $bpMedium) {
        margin-left: 0;
      }
    }
  }

  a {
    display: flex;
    align-items: flex-start;

    @media screen and (min-width: $bpMedium) {
      display: block;
    }
  }
}

.results {
  composes: results from '../filters/style.module.scss';
  position: relative;
}
