@import 'src/constants/index.scss';

.hidden-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.custom-checkbox {
  cursor: pointer;
  display: inline-block;
  vertical-align: sub;

  svg {
    display: block;

    &.filter-icon {
      height: 56px;
      width: 56px;
      fill: #555762;
      stroke: darken($offWhite, 2);
      display: block;

      @media screen and (max-width: $bpSmall) {
        height: 51px;
        width: 51px;
      }
    }

    &.default-checkbox {
      fill: none;
      stroke: darken($offWhite, 3);
      height: 15px;
      width: 15px;
      margin-right: 10px;

      @media screen and (max-width: $bpSmall) {
        margin-right: 5px;
      }

      path {
        &:last-of-type {
          stroke: none;
        }
      }
    }
  }
}

.is-checked {
  svg {
    &.filter-icon {
      circle {
        stroke: $roseBrown;
        stroke-width: 3;
      }
    }

    &.default-checkbox {
      stroke: #555762;
      fill: #555762;
    }
  }
}

.is-disabled {
  opacity: 0.5;
  cursor: default;
}
